import {useState,useEffect,React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';

import InvoiceGST from '../invoicebill_gst';

function Brsale()
{

	const [item,setitem]=useState([]);
	const [data,setdata]=useReducer(myreducer, initialstate);
    //const [totalAmount, setTotalAmount] = useState(0);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	var total=0;
	const handlePrintClick = (InvoiceNo, FinancialYear) => {
		
		setSelectedInvoice({ InvoiceNo, FinancialYear,option:1 });
	};
	async function loaddata()
	{
		const option=document.getElementById('ddlwise').value;
		const fy=document.getElementById('ddlfy').value;
        const d =new  Date;
       let month = d.getMonth()+1;

		// var a=await fetch(process.env.REACT_APP_URL+'branchsale?branchid='+localStorage.getItem("applicantID")+'&depotid=0&month='+month+'&invoiceno='+localStorage.getItem("applicantID")+'&option='+option+'&fy='+fy);
		// var b=await a.json();
		// var z=JSON.stringify(b.recordset);
        // var a=JSON.parse(z);
        //    setdata(a);
		   fetchbygetmethod(setdata, 'branchsale', { branchid: localStorage.getItem("applicantID"),depotid:0,month:month,invoiceno:localStorage.getItem("applicantID"),option:option,fy:fy });
	}	

	async function loaddatadatewise()
	{
		const option=document.getElementById('ddlwise').value;
		const fromdate=document.getElementById('txtfromdate').value;
        const todate =document.getElementById('txttodate').value;
       

		// var a=await fetch(process.env.REACT_APP_URL+'Depotsaledatewise?fromdate='+fromdate+'&todate='+todate+'&invoiceno='+localStorage.getItem("applicantID")+'&option='+option);
		// var b=await a.json();
		// var z=JSON.stringify(b.recordset);
        // var a=JSON.parse(z);
        //    setdata(a);
		//      console.log("salereportdatewise",data);
		fetchbygetmethod(setdata, 'Depotsaledatewise', { fromdate: fromdate,todate:todate,invoiceno:localStorage.getItem("applicantID"),option:option});
	}	
   
	useEffect(()=>{loaddata(); setSelectedInvoice(null)},[])
	useEffect(()=>{loaddatadatewise()},[])


    function displaytable() {
                   loaddata();
        document.getElementById("tabledisplay").style.display = "block";
}

function displaytabledatewise() {
                   loaddatadatewise();
        document.getElementById("tabledisplay").style.display = "block";
}


 
        


    return(
		<>
		{selectedInvoice ?
			<InvoiceGST
				invoiceno={selectedInvoice.InvoiceNo}
				financialyear={selectedInvoice.FinancialYear}
				option={selectedInvoice.option}
			/>
			:
			<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Sales Report</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
									

										<div class="row">
										

											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Financial Year</label>
															<select class="form-control" id='ddlfy'>
																<option>--Select--</option>
																<option value='5'>2020-2021</option>
																<option value='6'>2021-2022</option>
																<option value='7'>2022-2023</option>
																<option value='8'>2023-2024</option>
																<option selected='true' value='9'>2024-2025</option>


															</select>
														</div>
														<div class="form-group">
															<label>Date From</label>

															<div>
																<div>
																	<input id='txtfromdate' type="date" class="form-control" required />
																</div>
															</div>


														</div>






													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">

												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Wise Type</label>

															<select id="ddlwise" class="form-control">
																<option value='0'>--Select--</option>
																<option value='11'>Purchase Wise</option>
																<option value='9'>Sale Wise</option>
																<option value='10'>Sales Return Wise</option>
																<option value='12'>Purchase Return</option>
																<option>GST STORE Purchase</option>

															</select>

														</div>
														<div class="form-group">
															<label>Date To</label>
															<div>
																<div>
																	<input id='txttodate' type="date" class="form-control" required />
																</div>
															</div>
														</div>

													</form>




												</div>

											</div>



										</div>
										<div class="form-group">
											<div>
												<button type="submit"
													class="btn btn-primary waves-effect waves-light" onClick={displaytable}>
													Show</button>
												<button type="submit"
													class="btn btn-primary waves-effect m-l-5" style={{ marginLeft: 5 }} onClick={displaytabledatewise}>
													Show Date Wise</button>
											</div>
										</div>
										<div class="container">
											<div class="row justify-content-center mt-5">
												<div class="col-md-6">
													<form>
														<div class="input-group col-lg-12 ">
															<input type="text" class="form-control " placeholder="Search" aria-label="Search" />

														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row" id="tabledisplay" style={{ display: 'none' }}>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Sale Invoice</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													<th></th>
													<th>Invoice No</th>
													<th>Invoice Date</th>
													<th>Name</th>
													<th>Applicant Id</th>
													<th>Net Amount</th>



												</tr>
											</thead>
											<tbody>
												{data.mypost != null && data.mypost.length > 0 &&
													data.mypost.map((row, index) => {

														return (

															<tr>
																<td><button
																	onClick={() => handlePrintClick(row.InvoiceNo, row.FinancialYear)}
																>
																	Print
																</button></td>
																<td>{row.InvoiceNo}</td>
																<td>{row.InvoiceDate}</td>
																<td>{row.Name}</td>
																<td>{row.Tempid}</td>
																<td>{row.amt}</td>

															</tr>


														)
													})

												}
											</tbody>
											<tfoot>
											{data.mypost.length>0 &&	
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td><strong>{data.mypost.forEach((row)=> total+=row.amt)}Total={total.toFixed(2)}</strong></td>

												</tr>}


											</tfoot>

										</table>
									
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
			
		</div>
		}
		<Gotohome mydata={data} />
	</>
    )
}

export default Brsale;