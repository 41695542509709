import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod ,fetchbyputmethod} from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import axios from 'axios';
function Storepaneldetails() {
	const imagedefault = "/assets/images/users/avatar-1.jpg";
	const [data, setdata] = useReducer(myreducer, initialstate);
	const [myparentdataa, setmyparentdataa] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);
	const [updateParentId, setupdateParentId] = useReducer(myreducer, initialstate);

	//photo section

	const [file, setfile] = useState(null);
	const [fileimage, setfileimage] = useState(null);

	const handleFileChangeforaddress = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfileimage(reader.result);
				setfile(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitaddress = async (e) => {

		e.preventDefault();

		if (!file) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_address';
		// Create a new file with the new name
		const newFileName = `${imagename}.${file.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([file], newFileName, { type: file.type });

		const formData = new FormData();

		formData.append('file', newFile);


		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'dsuploadaddress', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });

			alert("Image Uploaded!!");
		} catch (err) {
			console.error('ret', err);
		}
	};
 
	async function loaddata() {

		fetchbygetmethod(setdata, 'panelDetails', { tempid: localStorage.getItem("tempID") });
	}

	async function loaddatadepotdata() {

		fetchbygetmethod(setmyparentdataa, 'Adminjoindepottostore', { });
	}

	async function UpdateParentId() {


		var updateid=document.getElementById('ddlparent').value;
		fetchbyputmethod(setupdateParentId, 'updatedsparentid', { ApplicantId:updateid,DSAPPID:localStorage.getItem('applicantID')});
	     console.log('Result',updateParentId);
		 console.log(updateid);
	}

	useEffect(() => { loaddata(); loaddatadepotdata(); }, [])
	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror == true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage == false && myimgupload.myloading == false) {
			alert('File uploaded Successfully');
			loaddata();

		}

		// }

	}, [myimgupload])
	useEffect(() => { if (data.mypost.length > 0) console.log("mydata", data); }, [data])

	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Panel Details</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Panel Details</h4>
										<hr width="100%" />
										
														{data.myloading == true ? <Spinner />
															: data.myerror == true ? <Nodatafound />
																: data.mypost.length > 0 &&
																data.mypost.map((mydata, index) => {
																	return (
																		<div class="row">
																			<div class="form-group">
																				<div>
																					<button type="submit"
																						class="btn btn-primary waves-effect waves-light">
																						Panel Details</button>
																				</div>
																			</div>
																			<div class="col-sm-6 col-xs-12">
																				<div class="m-t-20">
																					<form class="" action="#">
																						<div class="form-group">
																							<label>Panel Name</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.Name} class="form-control" />
																								:
																								<label class="form-control">{mydata.Name}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Country Name</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.CountryName} class="form-control" />
																								:
																								<label class="form-control">{mydata.CountryName}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>City Name</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.Cityname} class="form-control" />
																								:
																								<label class="form-control">{mydata.Cityname}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Mobile Number</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.mobileno} class="form-control" />
																								:
																								<label class="form-control">{mydata.mobileno}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Recommended Person</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.recommendedperson} class="form-control" />
																								:
																								<label class="form-control">{mydata.recommendedperson}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Recommended Person Mobile No</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.recommendedpersonmobileno} class="form-control" />
																								:
																								<label class="form-control">{mydata.recommendedpersonmobileno}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Is Block</label>
																							<select class="form-control">
																								<option>Select</option>
																								<option>Un Block</option>
																								<option>Block</option>
																							</select>
																						</div>
																					</form>
																				</div>
																			</div>
																			<div class="col-sm-6 col-xs-12">
																				<div class="m-t-20">
																					<form action="#">
																						<div class="form-group">
																							<label>State Name</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.StateName} class="form-control" />
																								:
																								<label class="form-control">{mydata.StateName}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Area Name</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.areaname} class="form-control" />
																								:
																								<label class="form-control">{mydata.areaname}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Email</label>
																							{/* Conditionally render label or input */}
																							{localStorage.getItem("parentType") == 'admin' ?
																								<input type="text" defaultValue={mydata.email} class="form-control" />
																								:
																								<label class="form-control">{mydata.email}</label>
																							}
																						</div>
														
																						<div class="form-group">
																							<label>Address Proof</label>
																							<div className="fallback">
																								<input type="file" class="form-control" onChange={handleFileChangeforaddress} />
																							</div>
																							<div>
																								<img data-toggle="modal" data-target=".bs-example-modal-lg" style={{ height: 300, width: 300 }}
																									src={fileimage ? fileimage : Array.isArray(data.mypost) && data.mypost.length > 0 && data.mypost[0].addressproof ? './uploads/' + data.mypost[0].addressproof : process.env.PUBLIC_URL + imagedefault} alt=""
																									className="img-circle" />
																							</div>
																						</div>
														
																						<div class="form-group">
																							<div>
																								<button type="submit" onClick={(e) => onSubmitaddress(e)}
																									class="btn btn-primary waves-effect waves-light">
																									Submit Address Proof</button>
																							</div>
																						</div>
														
																						{localStorage.getItem("parentType") == 'admin' &&
																							<div class="form-group">
																								<label>parent panel</label>
																								<select id='ddlparent' class="form-control">
																									{myparentdataa.myloading == true ? <Spinner />
																										: myparentdataa.myerror == true ? <Nodatafound />
																											: myparentdataa.mypost.length > 0 &&
																											myparentdataa.mypost.map((row, index) => {
																												return (
																													<option value={row.Applicantid}>{row.ApplicantName}--{row.Tempid}</option>
																												)
																											})
																									}
																								</select>
																							</div>
																						}
																					</form>

																					{localStorage.getItem("parentType") == 'admin' &&
																							<div class="form-group" >
																								<input type="Button" value='Submit' onClick={UpdateParentId} class="btn btn-primary waves-effect waves-light" />
																							</div>
																						}
																				</div>
																			</div>
																		</div>
																	)
																})
														}
														
													
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Gotohome mydata={data} />
		</div>
	)

}
export default Storepaneldetails;