import React, { useState, useEffect, useReducer,useRef } from "react";
import './invoice.css';
import logo from './logo.png';
import { fetchbygetmethod, initialstate, myreducer } from "../src/Commonfiles/Myreducer";
import { numberToWords } from "./Commonfiles/numbertowords";
//import {getdata} from './StoreAddEditSaleinvoice.js';

function InvoiceGST(props) {
                
  var totaltaxableamt=0,totalamt=0,totaltax1amt=0,totalweight=0;       
  const invoiceRef = useRef();
  const [data, setdata] = useReducer(myreducer, initialstate);
  const [invoicedetaildata, setinvoicedetaildata] = useReducer(myreducer, initialstate);
  const [amount,setamount]=useState(null);

  async function loaddata()
	{
    console.log("props",props);
    var querydata={
      invoiceno:props.invoiceno,
      option:99,
      financialyear:props.financialyear==undefined?9:9,
      gst:'Y'
    }
    console.log("querydata",querydata);
		 fetchbygetmethod(setdata,"invoicebill",querydata);
     console.log('data=',data);
	
}	
async function loadinvoicedetails() {
  //alert("props2",props);
  //console.log("props",props.invoiceno);
  var querydata = {
    invoiceno: props.invoiceno,
    option:props.option,
    financialyear:props.financialyear==undefined?9:9,
  
  }
  fetchbygetmethod(setinvoicedetaildata, "getinvoicedetails", querydata);

}
useEffect(() => { loaddata();loadinvoicedetails(); }, [])
useEffect(() => {console.log('data=', data);console.log('data1=', invoicedetaildata)
if(data.mypost != null && data.mypost.length>0)
{
 // alert("totaltaxableamt"+totaltaxableamt);
  totalweight= data.mypost.reduce((sum, row) => sum + (row.totalwt), 0).toFixed(2);
  totaltaxableamt= data.mypost.reduce((sum, row) => sum + (row.Quantity*row.CostPrice), 0).toFixed(2);
  totaltax1amt=  data.mypost.reduce((sum, row) => sum + (((row.Tax)*(row.Quantity*row.CostPrice))/100), 0).toFixed(2);
  //alert("totaltaxableamt"+totaltaxableamt);
  totalamt=parseFloat(totaltax1amt)+parseFloat(totaltax1amt)+parseFloat(totaltaxableamt);
  //add hsn item in new object
  let amounthsncodewise=[];
  data.mypost.forEach(newItem => {
    let amount = newItem.Quantity * newItem.CostPrice;
    let taxamount = ((newItem.Tax)*(newItem.Quantity * newItem.CostPrice))/100;
    const existingitem=amounthsncodewise.find(obj => obj.HSNCode === newItem.HSNCode)
    
    if (existingitem) {
        // If the HSN code exists, update the amount
        existingitem.taxableamount += amount;
        existingitem.taxamount += taxamount;
    } else {
        // If the HSN code does not exist, add the new item
        amounthsncodewise.push({
            "HSNCode": newItem.HSNCode,
            "taxableamount": amount,
            "taxamount": taxamount,
             "Tax":newItem.Tax
            
        });
    }
});
  
  setamount({totalweight:totalweight,totaltaxableamt:totaltaxableamt,
    totaltax1amt:totaltax1amt,totalamt:totalamt,amounthsncodewise:amounthsncodewise})
}
  
}
, [data]);
useEffect(()=>console.log(amount),[amount]);

  return (
    <div class="content-page">
    <div class="content">
       
        
        <div ref={invoiceRef} class="page-content-wrapper ">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="panel panel-primary">
                            <div class="panel-body">
                         
                                <div class="row">
                                
                                <div ref={invoiceRef}  id='invoice' className="invoice-container">
                                {
                      invoicedetaildata.mypost!=null && data!=null &&
                       invoicedetaildata.mypost.length>0 && data.mypost.length>0 &&
                       <>
                                <div className="header">
        <img src={logo} alt="Logo" />
        <div className="header-info">
        <h5>{invoicedetaildata.mypost[0].fromname}
                                {invoicedetaildata.mypost[0].fromlocation}
                                MOBILE NO:{invoicedetaildata.mypost[0].frommobileno} 
                                State Code:{invoicedetaildata.mypost[0].fromstatecode}</h5>
        </div>
      </div>
      <div className="invoice-title">
        <strong>*Tax Invoice*</strong>
        
      </div>
      <div className="invoice-details">
        <div>
        <div className="left-details" style={{marginLeft:17}}>
        <strong>GSTIN: {invoicedetaildata.mypost[0].fromgstin ? invoicedetaildata.mypost[0].fromgstin : 'NA'}</strong><br />
                                  <strong>PAN No: {invoicedetaildata.mypost[0].fromPanNo}</strong><br />
          <strong>Tax is payble on reverse Charge(Y/N): N</strong><br/>
          <strong>Invoice No: {invoicedetaildata.mypost[0].frominvoiceno}</strong><br/>
          <strong>Invoice Date : {invoicedetaildata.mypost[0].frominvoicedate}</strong>

        </div>
        </div>
        <div  >
        <div className="right-details" style={{marginRight:120}}>
        <strong>Transport Name : {data.mypost[0].transportname}</strong><br/>
          <strong>Vechile No:{data.mypost[0].VehicleNo}</strong><br/>
          <strong>GR No:{data.mypost[0].grno}</strong><br/>
          <strong>Date of Supply: {data.mypost[0].InvoiceDate}</strong><br/>
          {/* <p>Place of Supply : {data.mypost[0].packets}</p> */}
          <strong>Packets: {data.mypost[0].packets}</strong>

        </div>
        </div>
      </div>
      <div className="receiver-consignee">
        <div className="receiver">
          <h4>Details Of Reciever(Bill to)</h4>
          <strong>Name:  {data.mypost[0].Name}</strong><br/>
          <strong>Address: {data.mypost[0].Location}</strong><br/>
        
          <strong>State:  {data.mypost[0].StateName}</strong><br/>
          <strong>State Code:  {data.mypost[0].statecode}</strong><br/>
          <strong>PAN No:  {data.mypost[0].PanNo}</strong><br/>
          <strong>GSTIN No:  {data.mypost[0].gstin}</strong><br/>
          <strong>Mobile No: {data.mypost[0].mobileno}</strong>
        </div>
        <div className="consignee">
          <h4>Details Of Consignee(Ship to)</h4>
          <strong>Name:  {data.mypost[0].Name}</strong><br/>
          <strong>Address: {data.mypost[0].Location}</strong><br/>
        
          <strong>State:  {data.mypost[0].StateName}</strong><br/>
          <strong>State Code:  {data.mypost[0].statecode}</strong><br/>
          <strong>PAN No:  {data.mypost[0].PanNo}</strong><br/>
          <strong>GSTIN No:  {data.mypost[0].gstin}</strong><br/>
          <strong>Mobile No: {data.mypost[0].mobileno}</strong>
        </div>
      </div>
      </>
                      
                    }

      <div className="invoice-body">
       
        <table className="invoice-table">
          <thead>
            
            <tr>
                        <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                            <strong>S. No</strong>
                        </td>
                        <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                            <strong>Material Description 
                            </strong>
                        </td>
                        <td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                            <strong>B.NO.</strong>
                        </td>
                        <td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                            <strong>HSN Code</strong>
                        </td>
                        <td colspan="3" align="center" bgcolor="#EBEBEB" class="text4" style={{width: 250}}>
                            <strong>Quantity</strong>
                        </td>
                        <td rowspan="2" align="center" bgcolor="#EBEBEB" class="text4" >
                            <strong> Taxable Amount</strong>
                        </td>

                        <td colspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                            <strong>CGST</strong>
                           {/* <%-- <asp:Label ID="lblexempted" runat="server" Text="A"></asp:Label>--%> */}
                        </td>
                        <td colspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                            <strong>SGST</strong>
                            {/* <%--<asp:Label ID="lbltax1" runat="server" Text="A"></asp:Label>--%> */}

                        </td>
                        <td colspan="2" align="center" bgcolor="#EBEBEB" class="text4">
                            <strong>IGST</strong>
                            {/* <%--<asp:Label ID="lbltax2" runat="server" Text="A"></asp:Label>--%> */}

                        </td>
                        {/* <%--<td rowspan="2" align="right" bgcolor="#EBEBEB" class="text4">
                         <asp:Label ID="Label1" runat="server" Text="Total Amount"></asp:Label>
                            </td>--%> */}
                    </tr>
            
            <tr>
                        <td >
                            <strong>Qty.</strong>
                        </td>
                        <td >
                            <strong>U.O.M.</strong>
                        </td>
                        <td >
                            <strong>Unit Rate.</strong>
                        </td>
                        <td >
                            <strong>Rate</strong>
                        </td>
                        <td >
                            <strong>Amt</strong>
                        </td>
                        <td>
                            <strong>Rate</strong>
                        </td>
                        <td >
                            <strong>Amt</strong>
                        </td>
                        <td >
                            <strong>Rate</strong>
                        </td>
                        <td >
                            <strong>Amt</strong>
                        </td>


                        {/* <%-- <td align="right" bgcolor="#EBEBEB" class="text4">
                            <strong>Tax Rate.</strong>
                        </td>--%> */}
                    </tr>
          

          </thead>
          <tbody>
          {data.mypost != null && data.mypost.length > 0 &&
                                data.mypost.map((row, index) =>
            <tr>
              <td>{index + 1}</td>
              <td>{row.ItemName}</td>
              <td>{row.batchno}</td>
              <td>{row.HSNCode}</td>
              <td>{row.Quantity}</td>
              <td>piece</td>
              <td>{row.CostPrice.toFixed(2)}</td>
              <td>{(row.Quantity*row.CostPrice).toFixed(2)}</td>
              <td>{row.Tax}%</td>
              <td>{(((row.Tax)*(row.Quantity*row.CostPrice))/100).toFixed(2)}</td>
              <td>{row.Tax}%</td>
              <td>{(((row.Tax)*(row.Quantity*row.CostPrice))/100).toFixed(2)}</td>
              <td>{0}%</td>
              <td>{0}</td>
            </tr>
                                )}
          

            {/* <tr>
            <td colspan="7">Bill Value Amount In Words</td>
                <td >Total Taxable Amount</td>
                <td colspan="2">SGST:</td>
                <td colspan="2">CGST:</td>
                <td >IGST:</td>
                <td>TOTAL:</td>
            </tr> */}
           
              {
              amount!=null && 
              <tr>
               <td colspan="4"><strong>Bill Value Amount In Words</strong></td>
               <td colspan="3"><strong>Total Taxable Amount:</strong></td>
               <td ><strong> { amount.totaltaxableamt}</strong></td>
               <td colspan="2"><strong>SGST:{ amount.totaltax1amt}</strong></td>
               <td colspan="2"><strong>CGST:{amount.totaltax1amt}</strong></td>
               <td ><strong>IGST:0</strong></td>
               <td><strong>TOTAL:{amount.totalamt.toFixed(2)}</strong></td>
               </tr>
              }
           
           
          </tbody>
         
        </table>
        </div>
       
        <div className="invoice-body">
       <table className="invoice-table-sec" >
        <thead>
       
            <tr>

<td >
    <center>
    <strong style={{fontSize:10}} >
      
    { amount!=null && numberToWords(Math.round(amount.totalamt))}
</strong></center></td>

        <td  >
       
        <table style={{width:'100%'}}>
           
        <tr>
          <td>HSN CODE</td> 
 
 <td>TAXABLE AMOUNT</td>  
 <td>TAX SGST RATE(%)</td> 
 <td>TAX AMT</td> 
 <td>TAX CGST RATE(%)</td> 
 <td>TAX AMT</td>  
          </tr>
          {amount!=null  && amount.amounthsncodewise.length>0 &&
          amount.amounthsncodewise.map((row,index)=>{ return( <tr>
            <td>{row.HSNCode}</td>
            <td>{row.taxableamount.toFixed(2)}</td>
            <td>{row.Tax}%</td>
            <td>{row.taxamount.toFixed(2)}</td>
            <td>{row.Tax}%</td>
            <td>{row.taxamount.toFixed(2)}</td>
          </tr>)
           
          })
        
        
       
          }
          {amount!=null &&  <tr>
            <td>Total=</td>
            <td>{amount.totaltaxableamt}</td>
            <td>Total=</td>
            <td>{amount.totaltax1amt}</td>
            <td>Total=</td>
            <td>{amount.totaltax1amt}</td>
          </tr>}
          
          
          </table>
   
    </td>
    
    <td >
    <tr>
        <td > 
        Frieght Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Loading and Packing Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Insurance Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Other Charges</td>  
        <td>0</td> 
    </tr>
    <tr>
        <td>Invoice Total</td>  
        <td>{amount!=null && amount.totalamt.toFixed(2)}</td> 
    </tr>
   
 
    </td>
    
  </tr>

  <tr>

<td >
    <center>
    <strong style={{fontSize:10}} >
       Total Tax Amt :{amount!=null && amount.totaltax1amt>0? numberToWords(Math.round(amount.totaltax1amt*2)):0} 
</strong></center></td>

        <td  >
        <center>
    <strong style={{fontSize:10}} >
    Amount of Tax Subject to Reverse Charge: 
</strong></center>
    </td>
    <td>
    <center>
    <strong style={{fontSize:10}} >
    Total Amount After Tax:{amount!=null && amount.totalamt.toFixed(2)}
</strong></center>
   
 
    </td>
    
  </tr>
  <tr>

<td >
    <center>
    <strong style={{fontSize:10}} >
    Total Weight :{amount!=null && amount.totalweight } Kg
</strong></center></td>

        <td  >
        <center>
    <strong style={{fontSize:10}} >
    Round Off Total :(+){amount!=null && (amount.totalamt-Math.floor(amount.totalamt)).toFixed(2) }
</strong></center>
    </td>
    <td>
    <center>
    <strong style={{fontSize:10}} >
    Total Bill Values :{amount!=null && Math.round(amount.totalamt)}

</strong></center>
   
 
    </td>
    
  </tr>

          
        </thead>
        <tfoot>
          <tr>
            <td colSpan='12'>
            <div className="declaration">
            <strong>Declaration:</strong> Certified that the particulars given are true and correct and the amount indicated represents the price actually charged and there is no flow of additional consideration directly or indirectly from the buyer E & O E. <strong>Subject To Lucknow Jurisdiction only</strong>
          </div>
            </td>
          </tr>
        </tfoot>
       
       </table>
       </div>
      
         <div className="invoice-footer">
         
          <div className="received-goods">Received the above goods in good condition & Order

            <div className="received-goods" style={{marginTop:20}}>
            Receiver`s Signature and Stamp
            </div>
          </div>
         
          
          <div className="signature">
            <center>
            <div className="received-goods" >For JMVD Multitrade Pvt. Ltd.</div>
            <div className="received-goods" style={{marginTop:20}}>Authorised Signatory</div>
            </center>
          </div>
        </div>
       
     
      
    </div>
                                 
                                        </div>
                                
                                               

                                    </div>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                    
                </div>
              
            
            </div>
            
  
        </div>
  );
}

export default InvoiceGST;