import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import Invoice from "../invoicebill";

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function DepotAddEditsaleinvoice() {
    //from database
    const [showbill, setshowbill] = useReducer(myreducer, initialstate);
    const [item, setitem] = useReducer(myreducer, initialstate);
    const [panelchild, setpanelchild] = useReducer(myreducer, initialstate);
    const [myinvoiceno, setinvoiceno] = useReducer(myreducer, initialstate);

    //to database
    const [jsonsaleitems, setjsonsaleitems] = useState([]);
    const [jsonsaleinvoice, setjsonsaleinvoice] = useState(false);

    const [totalamount, settotalamount] = useState(0);
    const [totalbv, settotalbv] = useState(0);

     const [buttonClicked, setButtonClicked] = useState(false);


    function search() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("txtsearch");
        filter = input.value.toUpperCase();
        table = document.getElementById("datatable-responsive");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            for (var ci = 2; ci < 4; ci++) {
                td = tr[i].getElementsByTagName("td")[ci];
                if (td) {
                    txtValue = td.textContent || td.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                        break;
                    } else {
                        tr[i].style.display = "none";
                    }
                }//end if
            }//end loop column
        }//end for lopp row
    }

    function setcurrentdate() {
        const now = new Date();

        // Format the date as YYYY-MM-DD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        // Set the value of the input element
        document.getElementById('txtinvoicedate').value = currentDateTime;
        document.getElementById('txtinvoicedate').readOnly = true;

    }

    async function loaditemdata() {
      
        fetchbygetmethod(setitem, 'getitemsbyapplicantid', { applicanttype: localStorage.getItem("applicantType"), applicantid: localStorage.getItem('applicantID') });

    }

    async function loadpanelchilddata() {

        fetchbygetmethod(setpanelchild, 'getpanelchildbyparentid', { parentapplicantid: localStorage.getItem("applicantID") });

    }
    function createdatafordatabase(event) {
        event.preventDefault();
        const invoicenoselect=document.getElementById('txtinvoicetype');
      //const fillbranch=document.getElementById('ddlpanelchildbranch');
      const fillstore=document.getElementById('ddlpanelchilddepot');

      


      if(invoicenoselect.value==="0"){
        alert('Fill The Invoice Type');
      }


     // alert(  fillstore.value.length);
     else if (fillstore.value === "" || fillstore.value === "---Select---") {
        alert('First Fill Store ID');
    }

    else{ savedata();}
       
    setButtonClicked(true);
    }
    async function savedatatodatabase() {
       // savedata();
        //    console.log("jsonitemdata", jsonsaleitems);
        //    console.log("jsoninvoicedata", jsonsaleinvoice);
        try {


            fetchbypostmethod(setshowbill, 'saveinvoice', { 'jsondata': jsonsaleinvoice });

        } catch (error) {
            console.log("show error", error);
        }
    }

    function Displaydisable(whois) {
        // alert('hi');
        var Branch = document.getElementById('ddlpanelchildbranch');
        var Depot = document.getElementById('ddlpanelchilddepot');

        if (whois == 'branch') {
            Branch.disabled = false;
            Depot.disabled = true;
        }
        else {
            Branch.disabled = true;
            Depot.disabled = false;
        }
    }

    function additem(currentitem, e, index) {
       
       
        console.log("currentitem.Quantity:",currentitem.Quantity);
        console.log("jsonitems",jsonsaleitems);

        var currentsaleqty = parseInt(document.getElementById("txtsaleqty-" + index).value);
        //alert("currentsaleqty--"+currentsaleqty);
       
        currentsaleqty = isNaN(currentsaleqty) ? 0 : currentsaleqty;
        console.log("currentsaleqty:",currentsaleqty);
        settotalamount(isNaN(totalamount) ? 0 : totalamount);
        settotalbv(isNaN(totalbv) ? 0 : totalbv);

       // alert('10');
       try{
        if (jsonsaleitems ) //true or false(uncheck) i.e. remove item from json
        {
            console.log('1');
            const itemexists=jsonsaleitems.some(row=>row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno);
            if(itemexists)
            {

          
            // alert("old");
            // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);
            if (currentsaleqty > currentitem.Quantity)//stock is less then demand
            {
                alert("Please check Available Quantity of " + currentitem.itemname);

                e.target.value = 0; // Unselect the checkbox
                currentsaleqty = 0;

            }
            //alert(currentsaleqty);
            var rowindex = jsonsaleitems.findIndex((row) => (row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));

            var previoustotalbv = totalbv - (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty)
            var previoustotalamount = totalamount - (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty)


            // alert("previoustotalamount"+totalamount+"-"+(jsonsaleitems[rowindex].businessvolume ));//* jsonsaleitems[rowindex].Qty ));

            previoustotalamount = isNaN(previoustotalamount) ? 0 : previoustotalamount;
            previoustotalbv = isNaN(previoustotalbv) ? 0 : previoustotalbv;

            //step 1
            const updatedsingleitem={...jsonsaleitems[rowindex],Qty:currentsaleqty};
            //alert("previoustotalamount"+previoustotalamount);
            //step 2
           const updatedarrayitem=jsonsaleitems.filter((row) => !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));

           //step 3

           updatedarrayitem.push(updatedsingleitem);
          //  jsonsaleitems[rowindex].Qty = currentsaleqty;
          //step 4
          setjsonsaleitems(updatedarrayitem);
          

            settotalbv(previoustotalbv + (updatedsingleitem.businessvolume * updatedsingleitem.Qty));
            settotalamount(previoustotalamount + (updatedsingleitem.SellingPrice * updatedsingleitem.Qty));

            if (currentsaleqty == 0 || isNaN(currentsaleqty)) {
                setjsonsaleitems(jsonsaleitems.filter((row) => !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno)));

            }

              }//end some exists
              else{
                if(currentsaleqty>0) {
                   // alert("new");//            
                     currentitem.ItemName = currentitem.itemname;
                   currentitem.Qty = currentsaleqty;
                   currentitem.InvoiceType = "S";
                   currentitem.FromApplicantId = parseInt(localStorage.getItem("applicantID"));
                   currentitem.ApplicantId = document.getElementById("ddlpanelchilddepot").value;
                   currentitem.InvoiceNo = document.getElementById("txtinvoiceno").value;
                   currentitem.Rate = currentitem.SellingPrice;
                   currentitem.BV = currentitem.businessvolume;
                   currentitem.Unit = null;
                   currentitem.VAT = null;
                   currentitem.SAT = null;
                   currentitem.Status = 'Y';
                   currentitem.expirydate = null;
                   currentitem.surcharge = null;
                   currentitem.invoicereturn = null;
                   currentitem.IsFullValue = null;
                   // currentitem.SellingPrice1='';
                   currentitem.RelativeInvoiceDate = new Date(document.getElementById("txtinvoicedate").value);
                   currentitem.FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
                   currentitem.fromitemdetailid = null;
                   currentitem.toitemdetailid = null;
                   currentitem.InvoiceDate = null;
                   currentitem.GST = 'Y';
                   currentitem.batchno=currentitem.batchno;
                   // currentitem.BV='';
                   // currentitem.ItemId='';
                   // currentitem.ItemName='';
                   // currentitem.Quantity='';
       
       
       
                 
                   settotalamount(totalamount + (currentitem.SellingPrice * currentsaleqty));
                   settotalbv(totalbv + (currentitem.businessvolume * currentsaleqty));
       
                   jsonsaleitems ?
                   setjsonsaleitems([...jsonsaleitems, currentitem])
                   : setjsonsaleitems([currentitem]);
                   //totamt=totamt+amt;
                   //settotamt(totamt+amt);//100+200=300
       
               }
              }

            }
       
        else if (isNaN(currentsaleqty)) //if alphabet then error...only number needed 
        {
            alert("Please enter a valid quantity.");
            e.target.checked = false;//checkbox
            e.target.value = 0;
            return; // Exit function if saleqty is not a number
        }
        else if (currentsaleqty > currentitem.Quantity)//stock is less then demand
        {
            alert("Please check Available Quantity of " + currentitem.itemname);

            e.target.checked = false; // Unselect the checkbox
            e.target.value = 0;

        }
        else if(currentsaleqty>0) {
             //alert("new");//            
              currentitem.ItemName = currentitem.itemname;
            currentitem.Qty = currentsaleqty;
            currentitem.InvoiceType = "S";
            currentitem.FromApplicantId = parseInt(localStorage.getItem("applicantID"));
            currentitem.ApplicantId = document.getElementById("ddlpanelchilddepot").value;
            currentitem.InvoiceNo = document.getElementById("txtinvoiceno").value;
            currentitem.Rate = currentitem.SellingPrice;
            currentitem.BV = currentitem.businessvolume;
            currentitem.Unit = null;
            currentitem.VAT = null;
            currentitem.SAT = null;
            currentitem.Status = 'Y';
            currentitem.expirydate = null;
            currentitem.surcharge = null;
            currentitem.invoicereturn = null;
            currentitem.IsFullValue = null;
            // currentitem.SellingPrice1='';
            currentitem.RelativeInvoiceDate = new Date(document.getElementById("txtinvoicedate").value);
            currentitem.FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
            currentitem.fromitemdetailid = null;
            currentitem.toitemdetailid = null;
            currentitem.InvoiceDate = null;
            currentitem.GST = 'Y';
            currentitem.batchno=currentitem.batchno;
            // currentitem.BV='';
            // currentitem.ItemId='';
            // currentitem.ItemName='';
            // currentitem.Quantity='';



          
            settotalamount(totalamount + (currentitem.SellingPrice * currentsaleqty));
            settotalbv(totalbv + (currentitem.businessvolume * currentsaleqty));

            jsonsaleitems ?
            setjsonsaleitems([...jsonsaleitems, currentitem])
            : setjsonsaleitems([currentitem]);
            //totamt=totamt+amt;
            //settotamt(totamt+amt);//100+200=300

        }
        else{
            alert('2');
        }
    
    
}
catch(err){console.log('err',err)}

        // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);

//         if (jsonsaleitems && jsonsaleitems.includes(currentitem)) //true or false(uncheck) i.e. remove item from json
//         {
//                   // alert("old");
//             // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);
//             if (currentsaleqty > currentitem.Quantity)//stock is less then demand
//             {
//                 alert("Please check Available Quantity of " + currentitem.itemname);

//                 e.target.value = 0; // Unselect the checkbox
//                 currentsaleqty = 0;

//             }
//             //alert(currentsaleqty);
//             var rowindex = jsonsaleitems.findIndex((row) => (row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));
                 
//             var previoustotalbv = totalbv - (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty)
//             var previoustotalamount = totalamount - (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty)
                

// // alert("previoustotalamount"+totalamount+"-"+(jsonsaleitems[rowindex].businessvolume ));//* jsonsaleitems[rowindex].Qty ));

//             previoustotalamount = isNaN(previoustotalamount) ? 0 : previoustotalamount;
//             previoustotalbv = isNaN(previoustotalbv) ? 0 : previoustotalbv;

//             //alert("previoustotalamount"+previoustotalamount);
//             jsonsaleitems[rowindex].Qty = currentsaleqty;
           
//             settotalbv(previoustotalbv + (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty));
//             settotalamount(previoustotalamount + (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty));

//             if (currentsaleqty == 0 || isNaN(currentsaleqty)) {
//             setjsonsaleitems(jsonsaleitems.filter((row) => !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno)));
           
//             }



        
//         }
            
         
        
//             else if (isNaN(currentsaleqty)) //if alphabet then error...only number needed 
//             {
//                 alert("Please enter a valid quantity.");
//                 e.target.checked = false;//checkbox
//                 e.target.value = 0;
//                 currentsaleqty = 0;
//                 return; // Exit function if saleqty is not a number
//             }
//             else if (currentsaleqty > currentitem.Quantity)//stock is less then demand
//             {
//                 alert("Please check Available Quantity of " + currentitem.itemname);
    
//                 e.target.checked = false; // Unselect the checkbox
//                 e.target.value = 0;
//                 currentsaleqty = 0;
//             }
//         else{ if(currentsaleqty>0)
//             {

            
//             currentitem.ItemName = currentitem.itemname;
//             currentitem.Qty = currentsaleqty;
//             currentitem.InvoiceType = "S";
//             currentitem.FromApplicantId = parseInt(localStorage.getItem("applicantID"));
//             currentitem.ApplicantId = document.getElementById("ddlpanelchilddepot").value;
//             currentitem.InvoiceNo = document.getElementById("txtinvoiceno").value;
//             currentitem.Rate = currentitem.SellingPrice;
//             currentitem.BV = currentitem.businessvolume;
//             currentitem.Unit = null;
//             currentitem.VAT = null;
//             currentitem.SAT = null;
//             currentitem.Status = 'Y';
//             currentitem.expirydate = null;
//             currentitem.surcharge = null;
//             currentitem.invoicereturn = null;
//             currentitem.IsFullValue = null;
//             // currentitem.SellingPrice1='';
//             currentitem.RelativeInvoiceDate = new Date(document.getElementById("txtinvoicedate").value);
//             currentitem.FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
//             currentitem.fromitemdetailid = null;
//             currentitem.toitemdetailid = null;
//             currentitem.InvoiceDate = null;
//             currentitem.GST = 'Y';
//             currentitem.batchno=currentitem.batchno;
//             // currentitem.BV='';
//             // currentitem.ItemId='';
//             // currentitem.ItemName='';
//             // currentitem.Quantity='';


//             jsonsaleitems?
//             setjsonsaleitems([...jsonsaleitems, currentitem])
//             : setjsonsaleitems([currentitem]);
//             settotalamount((isNaN(totalamount) ? 0 : totalamount) + (currentitem.SellingPrice * currentsaleqty));
//             settotalbv( (isNaN(totalbv) ? 0 : totalbv) + (currentitem.businessvolume * currentsaleqty));
//             //totamt=totamt+amt;
//             //settotamt(totamt+amt);//100+200=300
//         }}
    
        
        //  
        //alert('currentid'+currentitem.ApplicantId);
        //  alert("additem" + JSON.stringify(jsonsaleitems));
    }
    function savedata() {

        //var appid= document.getElementById("ddlpanelchild").value;
        //alert("id"+appid);
        //  alert("ji"+ document.getElementById("ddlpanelchild").value);
        // var jsonreplace=JSON.stringify(jsonsaleitems);
        // var     saleitemdata = jsonreplace.replace(/\\/g, "");
        // console.log("replavce data",saleitemdata);
        var saleinvoice = {
            "StorageLocationId": 1,
            "FromApplicantId": parseInt(localStorage.getItem("applicantID")),
            "ApplicantId": document.getElementById("ddlpanelchilddepot").value,

            //(document.getElementById("txtdstempid").value.ApplicantId),
            //   document.getElementById("ddlpanelchildbranch").value:
            //   document.getElementById("ddlpanelchilddepot").value),
            "InvoiceType": document.getElementById("txtinvoicetype").value,
            "InvoiceNo": document.getElementById("txtinvoiceno").value,
            "InvoiceDate": new Date(document.getElementById("txtinvoicedate").value),
            "Reference": document.getElementById("txtreference").value,
            "FormNo": null,
            "PanNo": null,
            "VatNo": null,
            "VehicleNo": null,
            "Weight": null,
            "IsDelivered": "N",
            "Remark": null,
            "status": "Y",
            "packets": 1,
            "transportname": null,
            "DeliverAt": null,
            "invoiceamt": totalamount,
            "invoicebv": totalbv,
            "ip": null,
            "FinancialYear": parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
            "GST": "Y",
            "FrieghtCharges": null,
            "LPCharges": null,
            "InsuranceCharges": null,
            "OtherCharges": null,
            "ReverseCharge": null,
            "grno": null,
            "Dos": null,

            "SaleItems": jsonsaleitems
        }
        //console.log(saleinvoice);

        setjsonsaleinvoice(saleinvoice)
        //console.log("myjsondata",jsonsaleinvoice);
        //  savedatatodatabase();
    }
    useEffect(() => {

        loadpanelchilddata();
        setcurrentdate();
        console.log('showbillonload',showbill);

    }, []);

    useEffect(() => {

        filldataafterusestateupdates();
       

    }, [myinvoiceno]);
    
    useEffect(() => {

       console.log('showbill',showbill);
      

    }, [showbill]);
    useEffect(()=>{ if(jsonsaleinvoice)savedatatodatabase()},[jsonsaleinvoice])
   //useEffect(()=>{settotalamount(0);settotalbv(0);},[item])
    function filldataafterusestateupdates() {
        if (myinvoiceno.mypost != null && myinvoiceno.mypost.length > 0) {
            var Reference = document.getElementById("txtreference");
            var invoiceno = document.getElementById('txtinvoiceno');
            // console.log('getinvoicecount',dstempid);//.mypost!=null ? dstempid.mypost:'NA');
            invoiceno.value = "Depot" + localStorage.getItem("applicantID") + "-TI" + (parseInt(myinvoiceno.mypost[0].invoiceno)+1).toString();
            invoiceno.readOnly = true;
            Reference.value = myinvoiceno.mypost[0].invoiceno+1;
            Reference.readOnly = true;

            settotalamount(0);
            settotalbv(0);
        }

        // if (showbill.myerror == false) {
        //     setshowbill(true);
        // }
    }
    async function Createinvoiceno() {

        //alert("hiii");
        var ddlinvoicetype = document.getElementById('txtinvoicetype').value;
        var invoiceno = document.getElementById('txtinvoiceno');
        var FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
        var FromApplicantId = localStorage.getItem('applicantID');
        var Reference = document.getElementById("txtreference");



        fetchbygetmethod(setinvoiceno, 'getinvoicecount', { InvoiceType: ddlinvoicetype, FromApplicantId: FromApplicantId, FinancialYear: FinancialYear })


        //alert(invoiceno);


    }
useEffect(()=>{
        console.log("jsonsaleitemsbefore check1",jsonsaleitems);

      
           
    },[jsonsaleitems])


    return (<>{
        showbill.myerror == false && showbill.myloading == false  ?
         
        <Invoice invoiceno={document.getElementById('txtinvoiceno').value} financialyear={parseInt(process.env.REACT_APP_FINANCIAL_YEAR)} option={document.getElementById("txtinvoicetype").value=='S'?1:2}/>
            :

            <div className="content-page">
                <div className="content">
                    <div className="">
                        <div className="page-header-title">
                            <h4 className="page-title">Sale Invoice</h4>

                        </div>
                    </div>

                    <div className="page-content-wrapper ">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-body">
                                            <h4 className="m-t-0 m-b-30">Add /Edit Sale Invoice</h4>
                                            {/* <hr width="100%"/> */}
                                            <div className="row">
                                                <div className="form-group">
                                                    <div>


                                                        <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 800 }}>
                                                            Go Back</button>

                                                    </div>
                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Date</label>
                                                                <input type="datetime-local" id="txtinvoicedate" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Number</label>
                                                                <input type="text" id="txtinvoiceno" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Sale Invoice Type</label>
                                                                <select class='form-control' id="txtinvoicetype" onChange={Createinvoiceno}>
                                                                    <option value="0">---Select---</option>
                                                                    <option value="S" >Sale Invoice</option>
                                                                    <option value="T">Transfer Invoice</option>

                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Reference</label>
                                                                <input type="text" id="txtreference" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

 
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Store Id</label>
                                                                <select type="date" id="ddlpanelchilddepot" class='form-control' onChange={() => { Displaydisable('depot') }} required>
                                                                    <option>---Select---</option>
                                                                    {
                                                                        panelchild.mypost.length > 0 &&
                                                                        panelchild.mypost.map((row, index) => {

                                                                            return (
                                                                                <option value={row.ApplicantId}>{row.name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Return Invoice No.</label>
                                                                <input type="text" id="txtreturninvoiceno" class='form-control' placeholder="Return Invoice No." />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Branch Name</label>
                                                                <select type="date" id="ddlpanelchildbranch" class='form-control' onChange={() => { Displaydisable('branch') }}>
                                                                    <option>---Select---</option>
                                                                    {
                                                                        panelchild.mypost.length > 0 &&
                                                                        panelchild.mypost.map((row, index) => {
                                                                            if (row.ApplicantType === 'Branch')
                                                                                return (
                                                                                    <option value={row.ApplicantId}>{row.name}--({row.Tempid})</option>
                                                                                )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>





                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Vehicle Number</label>
                                                                <input type="text" id="txtvehicleno" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Remark</label>
                                                                <input type="text" id="txtremark" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Total Packet</label>
                                                                <input type="text" id="txtpacket" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Transport Name</label>
                                                                <input type="text" id="txtTransport" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Is Block</label>
                                                                <select class='form-control'>
                                                                    <option>Unblock</option>
                                                                    <option>Block</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Unit</label>
                                                                <select class='form-control'>
                                                                    <option>---Select Unit---</option>
                                                                    <option>PCS</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="form-group">
                                                    <div>

                                                        <input type="button" class="btn btn-primary waves-effect waves-light" value="Add Items" data-toggle="modal"
                                                            data-target=".bs-example-modal-lg" onClick={loaditemdata} style={{ marginLeft: 700, marginTop: 49 }} />


                                                        <div class="modal fade bs-example-modal-lg" tabindex="-1"
                                                            role="dialog" aria-labelledby="myLargeModalLabel"
                                                            aria-hidden="true">
                                                            <div class="modal-dialog modal-lg">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <button type="button" class="close"
                                                                            data-dismiss="modal" aria-hidden="true">×</button>
                                                                        <h4 class="modal-title" id="myLargeModalLabel">Select Item</h4>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className='Records'>
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="panel panel-primary">
                                                                                        <div class="container">
                                                                                            <div class="row justify-content-center mt-5">
                                                                                                <div class="col-md-6">
                                                                                                    <form>
                                                                                                        <div class="input-group col-lg-12 ">
                                                                                                            <input type="text" class="form-control " id='txtsearch' onChange={search} placeholder="Search" aria-label="Search" />

                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="panel-body">



                                                                                            <label style={{ color: 'red' }}>Total Amount =</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>{totalamount}</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>Total Bv =</label>
                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>{totalbv}</label>
                                                                                            <div class="table-container-Table">
                                                                                                <table id="datatable-responsive"
                                                                                                    class="table table-striped table-bordered dt-responsive nowrap"
                                                                                                    cellspacing="0" width="100%">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                           
                                                                                                            <th>Sale Quantity</th>
                                                                                                            <th>Item ID</th>
                                                                                                            <th>Item Name</th>
                                                                                                            <th>Item Rate</th>
                                                                                                            <th>BV</th>

                                                                                                            <th>Available Quantity</th>

                                                                                                            <th>Batch No</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        {item.myloading == true ? <Spinner />
                                                                                                            : item.myerror == true ? <Nodatafound />
                                                                                                                :
                                                                                                                item.mypost.length > 0 &&
                                                                                                                item.mypost.map((row, index) => {
                                                                                                                    let itemrow= jsonsaleitems?
                                                                                                                            jsonsaleitems.find(item=>(item.ItemId===row.ItemId && item.batchno===row.batchno))
                                                                                                                            :null;
                                                                                                                    let qty=itemrow ? itemrow.Qty:0;
                                                                                                                    return (<tr key={index}>
                                                                                                                       
                                                                                                                        <td><input type="number" defaultValue={qty} style={{ width: 75 }} id={"txtsaleqty-" + index}onChange={(e) => additem(row, e, index)} /></td>
                                                                                                                        <td>{row.ItemId}</td>
                                                                                                                        <td>{row.itemname}</td>
                                                                                                                        <td>{row.SellingPrice}</td>
                                                                                                                        <td>{row.businessvolume}</td>
                                                                                                                        <td>{row.Quantity}</td>
                                                                                                                        <td>{row.batchno}</td>

                                                                                                                    </tr>
                                                                                                                    );
                                                                                                                })}
                                                                                                    </tbody>

                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!buttonClicked && (
                                                        <button  onClick={(event)=>{createdatafordatabase(event)}}
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5, marginTop: 49 }} >
                                                            Submit</button>
                                                        )}
                                                    </div>
                                                </div>



                                            </div>








                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    }
    </>
    )

}


export default DepotAddEditsaleinvoice;