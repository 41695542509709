import React from 'react';

import {useReducer,useState,useEffect} from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import {  initialstate, myreducer,fetchbygetmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
//import background from '../../src/Images/idcardBackground.jpg';

//import '../DirectSeller/idcardBackground.jpg';


function DSIdCardSample() {


    const [mydata, setmydata] =useReducer(myreducer,initialstate);

 async function loaddata()
{
  //alert('1');
 	   fetchbygetmethod(setmydata,'idcarddetail',{tempid:localStorage.getItem("tempID")})
     // alert('2');

}	

useEffect(()=>{loaddata()},[])
useEffect(()=>{console.log("mydata",mydata)},[mydata])

  return (
    <div className="content-page">
      <div className="content">
        <div className="">
          <div className="page-header-title">
          <h4 className="page-title">ID Card<br/><br/>
          <Button onClick={()=>{ window.print();}}  className="btn btn-primary">Print</Button>
          </h4>
            
          
        
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="container">
          
										 
            <div className="row" > 

{mydata.initialstage==true?<></>: mydata.myloading==true
                                           ?<Spinner/>
                                           :mydata.myerror==true
                                           ?<Nodatafound/>
                                           :mydata.mypost.length >0 &&
                <div className="col-s-12" >
                  <div className="panel">
                    <div className="panel-body">
					<Container className="my-4">
      <Row className="justify-content-center"   style={{
     backgroundImage: `url('../Images/idcardBackground.jpg')`,
      backgroundSize: 'cover',
      height: '300px',
      width: '100%',
      marginTop:20
    }}>
       
      </Row>
    </Container>
   
                    </div>
                  </div>
                </div>
}
                    </div>
          
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default DSIdCardSample;
