import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';
import Invoice from '../../invoicebill_gst';
import InvoiceGST from '../../invoicebill_gst';

function Adminbranchdepotsale()
{
    

      const [mydata,setmydata]=useReducer(myreducer, initialstate);
      const [selectedInvoice, setSelectedInvoice] = useState(null);
      const [selectedInvoicetype, setSelectedInvoicetype] = useState(1);

      const handlePrintClick = (InvoiceNo, FinancialYear) => {
        // alert("InvoiceNo"+InvoiceNo);
        // alert("FinancialYear"+FinancialYear);
		setSelectedInvoice({ InvoiceNo, FinancialYear,selectedInvoicetype});
	};
    
     
    
      async function loaddatasaleretunwise()


   {

   // alert('2');
    var month=document.getElementById('txtmonth').value;
    var financialyear=document.getElementById('txtfinancialyear').value;
   
    fetchbygetmethod(setmydata, 'Adminbranchdepotsale',{month:month,financialyear:financialyear,option:2});

     //console.log("mydata",mydata);
   }

   async function loaddatasalewise()
   {

    //alert('1');
    var month=document.getElementById('txtmonth').value;
    var financialyear=document.getElementById('txtfinancialyear').value;
   
    fetchbygetmethod(setmydata, 'Adminbranchdepotsale',{month:month,financialyear:financialyear,option:1});

     //console.log("mydata",mydata);
   }
  



   useEffect(()=>{console.log("selectedInvoice",selectedInvoice) ;},[selectedInvoice]);

function display(option){
   option==1?loaddatasalewise():loaddatasaleretunwise();

   setSelectedInvoicetype(option);

   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
    return(
        <>
    

        {selectedInvoice ? 
            <InvoiceGST
              invoiceno={selectedInvoice.InvoiceNo}
              financialyear={selectedInvoice.FinancialYear}
              option={99}
              selectedInvoicetype={selectedInvoicetype}
            //   option={selectedInvoice.option}
            />
            :
    
    <div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Sales Report</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Sales Report</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:30}}>
                                                            View Sale Report</button>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:650}}>
                                                            Print</button>    
                                                        
                                                    </div>
                                                </div> 
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id="txtmonth">
                                                                <option >Select</option>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Financial Year</label>
                                                   <select class="form-control" id='txtfinancialyear'>
															    <option>Select</option>
                                                                <option value='1'>2016-2017</option>
                                                                <option value='2'>2017-2018</option>
                                                                 <option value='3'>2018-2019</option>
                                                                 <option value='4'>2019-2020</option>
                                                                <option value='5'>2020-2021</option>
                                                                <option value='6'>2021-2022</option>
                                                                <option value='7'>2022-2023</option>
                                                                <option  value='8'>2023-2024</option>
                                                                <option selected='true' value='9'>2024-2025</option>
																 

																</select>
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        	<div class="form-group">
															<div>
																<input type="submit" class="btn btn-primary waves-effect waves-light" value="Sale Invoice" style={{marginLeft:30}} onClick={()=>{display(1)}}/>
																	

                                                                    <input type="submit" class="btn btn-primary waves-effect waves-light" value="Sale Invoice Retun" style={{marginLeft:10}} onClick={()=>{display(2)}} />
																	
															</div>
														</div> 
                                                        
                                                       
                                                        	
                                                          
													
												</div>
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th></th>
												    <th>Invoice No</th>
												    <th>InvoiceDate</th>
													<th>Name</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
											{mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (		
											
                                              <tr>
													
													
                                                    <td><button
								 									onClick={() => handlePrintClick(row.Invoiceno, document.getElementById('txtfinancialyear').value)}
																>
																	Print
																</button></td>
                                                    <td>{row.Invoiceno}</td>
                                                    <td>{row.Date}</td>
                                                    <td>{row.TempID}</td>
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                        ))
												
                                    ) : null}
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>

}
{/* <Gotohome mydata={data} /> */}
</>
)


}

export default Adminbranchdepotsale;