import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../../Commonfiles/Myreducer';
import Spinner from '../../Commonfiles/spinner';
import Nodatafound from '../../Commonfiles/nodatafound';
import { Gotohome } from '../../Commonfiles/checkfortoken';

function AdminGeneratePayout()
{
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
	

	async function loaddata() {
		
        var month=document.getElementById('selectmonth');
        var year=document.getElementById('selectyear');
	  
		fetchbygetmethod(setmydata, 'AdminGeneratePayout', {month:month.value,year:year.value});
		
		console.log("mydata", mydata);
	  }


      function display(){
        loaddata();
       var data=document.getElementById("tabledata");
        data.style.display='block'
    
      }
    
    // useEffect(()=>{
      
    //     loaddata();
       
           
    // },[])

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Direct Seller Payout</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Generate Payout</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id='selectmonth'>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id='selectyear'>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option selected='true' >2024</option>
                                                                <option >2025</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" onClick={display} style={{marginLeft:1000}}>
                                                            Submit</button>    
                                                          
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                   
										<h4 class="m-b-30 m-t-0">Payout</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Payout Id</th>
												    <th>Month Name</th>
													<th>Year</th>
                                                    <th>Payout Date</th>
                                                    <th>Total Comission</th>
                                                    <th>Total Royality</th>
                                             
                                                    <th></th>
                                                    <th></th>
                                            
                                              
													</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.PID}</td>
                                                    <td>{row.monthname}</td>
                                                    <td>{row.Year}</td>
                                                    <td>{row.PayoutDate}</td>
                                                    <td>{row.TotalComission}</td>
                                                    <td>{row.TotalRoyality}</td>
                                                    <td> <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light">
                                                            View Comission</button> </td>
                                                    <td> <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light">
                                                            View Royality</button> </td>
                                                   
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
									
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminGeneratePayout;