import React, {useState,useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function Coupansdetails()
{
	//alert('hi');

    var [mydata,setmydata]=useReducer(myreducer, initialstate);
    var [myremainingcoupan,setmyremainingcoupan]=useReducer(myreducer, initialstate);
	
   

 async function loaddata()
  {
   

   fetchbygetmethod(setmydata, 'coupandetails', { tempid:localStorage.getItem("tempID") }); 
  }

  async function loaddata2()
  {
   

   fetchbygetmethod(setmyremainingcoupan, 'remainingcoupan', { applicantid:localStorage.getItem("applicantID") }); 
  }
  useEffect(()=>{loaddata(); loaddata2();},[])


    return(<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Dream Club Coupan Details </h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">You earned 30 Coupans(each of 500 Rs.) worth Of 15000 Rs.</h4>
                                        
                                        <h3 style={{color:'GrayText'}}>Coupan Summary</h3>
										<hr width="100%"/>
                                        
										<div class="row" id="tabledata"  >
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    {mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												myremainingcoupan.mypost.length > 0 &&
												myremainingcoupan.mypost.map((row,index)=>{

                                              return(
										<h4 class="m-b-30 m-t-0">Your's Remaining Coupans({row.itemname}) = {row.remainingcoupon} </h4>) 
                                    })
                                      
                                      }
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													<th>Sr.No</th>
													<th>Store No.</th>
                                                    <th>Applicant Name</th>
													<th>Invoice No.</th>
													<th>Date</th>
													<th>Discount</th>
													
												</tr>
											</thead>
											<tbody>
												
											
											
												
												{mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
											: mydata.myerror == true ? <Nodatafound />
												:
												mydata.mypost.length > 0 &&
												mydata.mypost.map((row,index)=>{

                                              return(<tr>
													<td>{index+1}</td>
													<td>{row.tempid}</td>
													<td>{row.applicantname}</td>
													<td>{row.invoiceno}</td>
													<td>{row.date}</td>
                                                    <td>{row.discountamt}</td>
													
												</tr>) 
                                              })
												
                                                }
											</tbody>
											{/* <tfoot>
											
												
											{mydata.mypost.length>0 &&	
											<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td><strong>{mydata.mypost.forEach((row)=> total+=row.inc)}Total={total}</strong></td>
													
												</tr>
												}
											</tfoot> */}
										</table>
									</div>
								</div>
							</div>
						</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<Gotohome mydata={mydata}/>
		</div>)
}

export default Coupansdetails;