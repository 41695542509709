import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Companybankdetails from './Depot/Companybankdetails';
import Depotbankdetails from './Depot/Depotbankdetail';
import D_communicationDetails from './Depot/Depotcommunicationdets';
import Depotleftmenu from './Depot/Depotleftmenu';
import Depotpaneldetails from './Depot/Depotpaneldetails';
import Depotpersonaldetail from './Depot/Depotpersonaldetail';
import Depotpuraprov from './Depot/Depotpurchaseapprove';
import Depotsalevoucher from './Depot/Depotsalevoucher';
import Depotstock from './Depot/Depotstock';
import Depottopbar from './Depot/Depottopbar';
import DepotAddEditsaleinvoice from './Depot/DepotAddEditsaleinvoice';
import DepotsaleNpurhistory from './Depot/DepotsaleNpurchasehistory';

import Depotproductlist from './Depot/depotproductlist';
import Depotdashboard from './Depot/depotdashboard';

export default function Depotroutes() {

    return (
        <Routes>
            <Route path='/' element={<Depotpersonaldetail isLoggedIn={localStorage.getItem("islogin")} />} />
            <Route path='D_personaldetail' element={<Depotpersonaldetail isLoggedIn={localStorage.getItem("islogin")} />} />
            <Route path='D_communicationdetail' element={<D_communicationDetails />} />
            <Route path='D_paneldetail' element={<Depotpaneldetails />} />
            <Route path='D_puchaseapprove' element={<Depotpuraprov />}></Route>
            <Route path='D_bankdetail' element={<Depotbankdetails />} />
            <Route path='D_salevoucher' element={<Depotsalevoucher />} />
            <Route path='D_stock' element={<Depotstock />} />
            <Route path='depotproductlist' element={<Depotproductlist/>}/>
            <Route path='D_sNpHistory' element={<DepotsaleNpurhistory/>} />
            <Route path='D_companybankdetails' element={<Companybankdetails />} />
            <Route path='DepotAddEditsaleinvoice' element={<DepotAddEditsaleinvoice/>}/>
            <Route path='depotdashboard' element={<Depotdashboard/>}/>
        </Routes>
    )
}