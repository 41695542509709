import { Link} from 'react-router-dom'
import React, { useState, useEffect , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
function Depotleftmenu(props)
{
    //const [value,setvalue]=useState(0);
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    //const imagefromdb="https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagefromdb = "./uploads/";
    const imagedefault="/assets/images/users/avatar-1.jpg";
    let z;
    async function loaddata()
    {
        // var x = await fetch(process.env.REACT_APP_URL+"profileinformation?tempid="+localStorage.getItem("tempID"));
        // console.log("id: "+localStorage.getItem("tempID"));
        // console.log("URL: "+x);
        // var y = await x.json();
        // z = await y.recordset;
        // console.log('dataprofileleft',z );
        // setdata(z);
        // console.log('dataprofileinfoleft',mydata );
        fetchbygetmethod(setmydata, 'profileinformation', { tempid: localStorage.getItem("tempID") });
    
        
    }
    
    useEffect(()=>{
      
        loaddata();
        
           
    },[])
    
    return(<div className="left side-menu">
    <div className="sidebar-inner slimscrollleft">
        <div className="user-details">
            <div className="text-center">
                <img src={mydata.mypost.length>0 && mydata.mypost[0].ApplicantPhoto ? imagefromdb+mydata.mypost[0].ApplicantPhoto:process.env.PUBLIC_URL+imagedefault} alt=""
                    className="img-circle"/>
            </div>
            <div className="user-info">
                <div className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown"
                        aria-expanded="false">{mydata.mypost.length>0 && mydata.mypost[0].ApplicantName}</a>
                    <ul className="dropdown-menu">
                        <li><a href="javascript:void(0)"> Profile</a></li>
                        <li><a href="javascript:void(0)"> Settings</a></li>
                        <li><a href="javascript:void(0)"> Lock screen</a></li>
                        <li className="divider"></li>
                        <li><a href="javascript:void(0)"> Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="sidebar-menu">
            
            <ul >
                {/* <li><Link to='depotdashboard' className="waves-effect"><i
                        className="mdi mdi-home"></i><span> Dashboard <span
                            className="badge badge-primary pull-right">1</span></span></Link></li> */}
                <li className="has_sub" onClick={()=>{ var a=document.getElementById('D_registrationdata');
                            a.style.display=a.style.display=='none'?'block':'none';
                            console.log("working");}}><a href="javascript:void(0);"
                    className="waves-effect">
                        <i className="mdi mdi-album"></i> 
                        <span>Registration Data </span> 
                        <span className="pull-right">
                        <i className="mdi mdi-plus"></i></span></a>

                        {/* <li><Link to='depotdashboard' class="waves-effect"><i
                        class="mdi mdi-home"></i><span>Dashboard<span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li> */}
                           
                <ul id='D_registrationdata' className="list-unstyled">
                        <li><Link to='D_personaldetail' >Personal Detail</Link></li>
                        <li><Link to="D_communicationdetail">Communication Detail</Link></li>
                        <li><Link to="D_bankdetail">Bank Detail</Link></li>
                        <li><Link to="D_paneldetail">Panel Detail</Link></li>
                        
                    </ul>
                  
                    </li>
                    <li className="has_sub" onClick={()=>{var a=document.getElementById('D_inventorytransactions');
                             a.style.display=a.style.display=='none'?'block':'none';
                        }}
                            ><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Inventory Transaction </span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                     <ul id='D_inventorytransactions' className="list-unstyled">
                           
								<li><Link to='D_salevoucher'>Sale Voucher </Link></li>
								<li><Link to='D_puchaseapprove'>Purchase Approve</Link></li>
                            
                        </ul> 
                        </li>

                   <li className="has_sub" onClick={()=>{var a=document.getElementById('D_inventoryreport');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Inventory Report </span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='D_inventoryreport' className="list-unstyled">
                           
								<li><Link to='D_sNpHistory'>Sale/Purchase History </Link></li>
								<li><Link to='D_stock'>Stock</Link></li>
                                {/* <li><Link to='D_conbusrep'>Consolidated Business Report</Link></li> */}
                            
                        </ul></li>     
                
                       {/* <li><Link to='D_yourkyc' class="waves-effect"><i
									class="mdi mdi-home"></i><span> Your KYC  <span
							class="badge badge-primary pull-right">1</span></span></Link></li> */}
								{/* <li><Link to='D_paymentclearance' class="waves-effect"><i
											class="mdi mdi-home"></i><span> Payment Clearance  <span
												class="badge badge-primary pull-right">1</span></span></Link></li> */}
                                {/* <li><Link to='D_productorderform' class="waves-effect"><i
											class="mdi mdi-home"></i><span> Product Order Form <span
												class="badge badge-primary pull-right">1</span></span></Link></li> */}
                                <li><Link to='D_companybankdetails' class="waves-effect"><i
											class="mdi mdi-home"></i><span> Company Bank Details  </span></Link></li>                                
								<li><Link to='depotproductlist' class="waves-effect"><i
													class="mdi mdi-home"></i><span> Product List  </span></Link></li>					
                    
            </ul>
        </div>
        <div className="clearfix"></div>
    </div>
    <Gotohome mydata={mydata} />
     </div>
    ) 
   
}

export default Depotleftmenu;