import './registrationform.css';
import React from 'react';
import { useState, useEffect } from "react";
//import { fetchbygetmethod, fetchbypostmethod,initialstate, myreducer } from "../../src/Commonfiles/Myreducer";
//import e from 'express';

 
const RegistrationForm = () => {

    const [dstempid, setdstempid] = useState(null);

    const [dstempid1, setdstempid1] = useState(null);
    const [mydata,setmydata]=useState(null);
    const [getcountry,setgetcountry]=useState(null);
    const [getcity,setgetcity]=useState(null);
    const [getstate,setgetstate]=useState(null);
    const [selectedStateId, setSelectedStateId] = useState(null);
  
    const[jsonregistration,setjsonregistration]= useState(null);


    async function Getcountryname() {
      await fetch(process.env.REACT_APP_URL + "getcountryname", {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          }
      })
          .then((response) => response.json())
          .then((data) => {
              setgetcountry(data.recordset);
          })
          .catch((err) => {
              console.log("error", err);
          });
  }



async function Getstatesname() {
  await fetch(process.env.REACT_APP_URL + "getstatesname", {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      }
  })
      .then((response) => response.json())
      .then((data) => {
          setgetstate(data.recordset);
      })
      .catch((err) => {
          console.log("error", err);
      });
}

async function Getcityname(stateid) {
  if (!stateid) {
      console.log("Invalid state ID:", stateid);
      return; // Don't make the request if the stateid is invalid
  }

  await fetch(process.env.REACT_APP_URL + "getcityname?stateid=" + stateid, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      }
  })
      .then((response) => response.json())
      .then((data) => {
          setgetcity(data.recordset);
      })
      .catch((err) => {
          console.log("error", err);
      });

  //alert("Selected State ID: " + stateid);
}


  async  function loaddsdata() 
    {
      var tempid = document.getElementById('txtdstempid');
      await fetch(process.env.REACT_APP_URL+"dspersonaldetails?tempid="+tempid.value,{
        method: "GET",
        headers: { "Content-Type": "application/json" ,
                 //'authorization':`Bearer ${localStorage.getItem("token")}`
                 }
        // body: JSON.stringify({
        // 	applicantid: localStorage.getItem("applicantID")

        // })
    }
  )
    .then((response) => { return  response.json() })
      
    .then((data) => {//console.log("llllll",data); 
                       setdstempid(data.recordset )})
    .catch((err) => { console.log("error",err);
                       })
}
     
async  function loaddsdata1() 
{
  var tempid1= document.getElementById('txtdstempid1');
  await fetch(process.env.REACT_APP_URL+"dspersonaldetails?tempid="+tempid1.value,{
    method: "GET",
    headers: { "Content-Type": "application/json" ,
             //'authorization':`Bearer ${localStorage.getItem("token")}`
             }
    // body: JSON.stringify({
    // 	applicantid: localStorage.getItem("applicantID")

    // })
}
)
.then((response) => { return  response.json() })
  
.then((data) => {//console.log("llllll",data); 
                   setdstempid1(data.recordset )})
.catch((err) => { console.log("error",err);
                   })
}

     
     function filldstempidafterusestateupdates()
         {
             if (dstempid != null && dstempid.length > 0) {
                 
                 var dsSponsorName = document.getElementById('SponsorName');
                 var dsapplicantID = document.getElementById('lblapplicantid');

                 console.log(dstempid);
                
                 
                 dsSponsorName.textContent = dstempid[0].ApplicantName;// +","+ dstempid.mypost[0].ApplicantId;
                 dsapplicantID.textContent = dstempid[0].ApplicantId;
                 
                 //dsSponsorName.ApplicantId.style.display = "none";
     
             }
             
            
     
             console.log(dsSponsorName);
       
      
     
     }
     
     function filldstempidafterusestateupdates1()
         {
             if (dstempid1!= null && dstempid1.length>0) {
                 
                 var dsawarenessname = document.getElementById('AwarenessName');
                 var dsapplicantID1 = document.getElementById('lblapplicantid1');
                    
                 //console.log(dstempid1);
       
                 dsawarenessname.textContent = dstempid1[0].ApplicantName;// +","+ dstempid1.mypost[0].ApplicantId;
                 dsapplicantID1.textContent = dstempid1[0].ApplicantId;
              
                    // alert(dsawarenessname);
             }
            
     
             console.log(dsawarenessname);
       
      
     
     }

     function createdatafordatabase (e) {
      e.preventDefault(); // Prevent the default form submission
      const dsregistration = {
        "sponsorids": document.getElementById('txtdstempid').value,
        "awarenessids": document.getElementById('txtdstempid1').value,
        "Member_PW": document.getElementById("Password").value,
        "position": document.getElementById("Position").value,
        "ApplicantName": document.getElementById("Applicantname").value,
        "GuardianName": document.getElementById("Guardianname").value,
        "NomineeName": document.getElementById("Nomineename").value,
        "DOB": document.getElementById("Appdob").value,
        "NDOB": document.getElementById("Nomdob").value,
        "Gender": document.getElementById("Grnder").value,
        "Mobile": document.getElementById("Number").value,
        "Email": document.getElementById("Email").value,
        "CityId": document.getElementById("city").value,
        "CountryId": document.getElementById("Country").value,
        "stateid": document.getElementById("states").value
        //"Registrationdetail": jsonregistration
      };
      console.log(dsregistration);
      setjsonregistration(dsregistration);
    };


  
     
  async function Dsregisterdata() {
    var Password= document.getElementById("Password");
    var NewPassword= document.getElementById("Conpassword");
    var checkbox=document.getElementById("terms");

    var requiredFields = document.querySelectorAll('#myForm input[required], #myForm select[required]');
    var allFilled = true;

    requiredFields.forEach(function(field) {
        if (field.value.trim() === '' || field.value === 'Select Country') {
            alert(`Please fill in the ${field.placeholder || field.id} field.`);
            field.focus(); // Focus on the empty field
            allFilled = false;
            return; // Exit the loop on the first empty field
        } 
    });

    if (!allFilled) {
        return; // Stop further execution if any required field is empty
    }

  
  var mobileNo = document.getElementById('Number').value;
  
  // Ensure the number is exactly 10 digits long
  if (mobileNo.length !== 10) {
    alert("Mobile number must be exactly 10 digits.");
    document.getElementById('Number').focus();
    return;
  }
  
    if(Password.value!=NewPassword.value)
    {
      alert('Confirm Password Does Not Match.');
     return;
    }

    else if(!checkbox.checked){

      alert('Please agree to the Terms and Conditions');
     return;

    }
      
    else
    await fetch(process.env.REACT_APP_URL + "dsregistration", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            // 'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonregistration) 
    })
    .then((response) => response.json())
    .then((data) => {
      //alert(data);

      //console.log("data",data);

        setmydata(data.recordset); 
        //alert(setmydata);
        console.log("Registration successful:", data); 
    })
    .catch((err) => {
        console.log("Registration error:", err); 
    });
    window.location.reload();
}









     useEffect(() => {
console.log("dstempid",dstempid);

              if(dstempid!=null)
        filldstempidafterusestateupdates();
           
      
       
      
      }, [dstempid]);
      
      useEffect(() => {
       
        console.log("dstempid1",dstempid1);
                      
                     if(dstempid1!=null)
                      filldstempidafterusestateupdates1();
              
               
              
              }, [dstempid1]);
              
     

      useEffect(()=>{ if(jsonregistration!=null) Dsregisterdata()},[jsonregistration]);

      useEffect(()=>{console.log("mydata",mydata);
        if(mydata!=null)
          {
             if(mydata[0].status=='invalidsponsorid')
               alert(" please check sponsor id");

             else if (mydata[0].status=="no")
              alert("Registration unsuccessful");

             else 
             alert("Registration is successful. Your DS ID is("+ mydata[0].status+").");

}

      },[mydata])
     

      useEffect(() => {
        Getcountryname(); // Call the function here
    }, []);
    
    useEffect(() => {
      Getstatesname(); // Call the function here
  }, []);
  
  useEffect(() => {
    Getcityname(); // Call the function here
}, []);



    return(
       <div className="form-container">
        <form className="registration-form" id='myForm'>
          <h2>Create an account</h2>
          
          <div className="form-row">
          <input type="text"  placeholder="Sponsor No"  onChange={loaddsdata}  id='txtdstempid' required />
          <input type="text" placeholder="Awareness No"  onChange={loaddsdata1} id='txtdstempid1' required />
          </div>
          
          <div className="form-row">
            <label placeholder="Sponsor Name"  style={{fontSize:13}}  className="lblapplicantname"  id='SponsorName'  ></label>
            <label placeholder="Awareness Name" style={{fontSize:13}}   className="lblapplicantname" id='AwarenessName'  ></label>
          </div>
          <div className="form-row">
            <label placeholder="Sponsor Name"  style={{fontSize:13,display:'none'}}  className="lblapplicantname"  id='lblapplicantid' required ></label>
            <label placeholder="Awareness Name" style={{fontSize:13,display:'none'}}   className="lblapplicantname" id='lblapplicantid1' required ></label>
          </div>
  
          <div className="form-row">
            <select id='Position' required>
              <option >Select Position</option>
              <option value="Left">Left</option>
              <option value="Right">Right</option>
            </select>
            <input type="text" placeholder="Guardian Name" id='Guardianname' required />
          </div>
  
          <div className="form-row">
            <input type="text" placeholder="Applicant Name" id='Applicantname' required/>
            <input type="date" placeholder="Applicant DOB" id='Appdob' required />
          </div>
          
          <div className="form-row">
            
            <input type="text" placeholder="Nominee Name" id='Nomineename' required />
            <input type="date" placeholder="Nominee DOB" id='Nomdob' required />

          </div>
          
          
          
          <div className="form-row">
            <input type="text" placeholder="Contact No" id='Number' required />
            <input type="email" placeholder="Email" id='Email' required />
          </div>
  
          <div className="form-row">
          <select id='Country' required>
                <option>Select Country</option>
                {getcountry && getcountry.map((country, index) => (
                    <option key={index} value={country.Countryid}>
                        {country.CountryName}
                    </option>
                ))}
            </select>
           
         <select required
                id='states' 
                onChange={(e) => {
                    const selectedId = e.target.value;
                    setSelectedStateId(selectedId);  // Update the selected StateId
                    Getcityname(selectedId);         // Fetch cities for the selected StateId
                }}
            >
                <option value="">Select State</option>
                {getstate && getstate.map((state, index) => (
                    <option key={index} value={state.StateId}>
                        {state.StateName}
                    </option>
                ))}
            </select>
          </div>
          
          <div className="form-row">
         

<select id='city' required>
                <option value="">Select City</option>
                {Array.isArray(getcity) && getcity.map((city, index) => (
                    <option key={index} value={city.CityId}>
                        {city.CityName}
                    </option>
                ))}
            </select>
            <select id='Grnder' required>
            <option >Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Other</option>
            </select>
          </div>
  
  
          <div className="form-row">
            <input type="password" placeholder="Password" id='Password' required />
            <input type="password" placeholder="Confirm Password" id='Conpassword' required />
          </div>
  
          <div className="form-row">
            <input type="checkbox" id="terms" />
            <label htmlFor="terms" style={{marginRight:602,marginTop:10}}>I agree with the <a href="#">Terms and Conditions</a></label>
          </div>
  
          <div className="form-row">
            <button type="submit" onClick={(e=>{createdatafordatabase(e)})} >Submit</button>
          </div>
  
          {/* <p>Already have an account? <a href="#">Sign In</a></p> */}
        </form>
      </div>);
}

export default RegistrationForm;