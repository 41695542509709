import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminDownlineRepurchaseIncome()
{
    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    let totalSumBV = 0;
      async function loaddata()
   {
    var fronmonth=document.getElementById('txtfrommonth').value;
    var tomonth=document.getElementById('txttomonth').value;
     var fromyear=document.getElementById('txtfromyear').value;
     var toyear=document.getElementById('txttoyear').value;
     var dsid=document.getElementById('txtdsid').value;
   

    fetchbygetmethod(setmydata, 'AdminDownlineRepurchaseIncome',{frommonth:fronmonth,tomonth:tomonth,
                                                                  fromyear:fromyear,toyear:toyear,tempid:dsid});

     console.log("mydata",mydata);
   }
      


   function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Downline Repurchase Income</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Downline Repurchase Income</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Enter DSID</label>
                                                    <input type="text" class="form-control" id='txtdsid'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                              
                                    
                                     
                                    
                                </div>
                              
                                <div className="row">
                                
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>From Month</label>
                                                    <select class="form-control" id='txtfrommonth'>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>To Month</label>
                                                    <select class="form-control" id='txttomonth'>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    

                                     
                                    
                               
                                    
                                     
                                    
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>From Year</label>
                                                    <select class="form-control" id='txtfromyear'>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option>2024</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>To Year</label>
                                                    <select class="form-control" id='txttoyear'>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option selected='true'>2024</option>
                                                                <option>2025</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>

                                      <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:1000}}
                                                            onClick={() => { display(); loaddata(); }}>
                                                            Submit</button>    
                                                         <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Download</button>       
                                                        
                                                    </div>
                                                </div> 

                                    </div>




                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                        <div
          style={{
            maxHeight: '500px', // Set a fixed height to control vertical scrolling
            overflowY: 'auto', // Enable vertical scroll
            overflowX: 'auto', // Enable horizontal scroll if needed
          }}
        >
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                <th>Sr No</th>
                                               
												    <th>Total BV</th>
												    <th>Applicant Name</th>
													<th>Temp ID</th>
                                                    <th>Mobile No</th>
                                                    <th>January</th>
                                                    <th>February</th>
                                                    <th>March</th>
                                                    <th>April</th>
                                                    <th>May</th>
                                                    <th>June</th>
                                                    <th>July</th>
                                                    <th>August</th>
                                                    <th>September</th>
                                                    <th>October</th>
                                                    <th>November</th>
                                                    <th>December</th>
                                                    
                                                    
                                              
													</tr>
											</thead>
											<tbody>
                                            {mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                                const totalBV = 
                                                (parseFloat(row.January) || 0) +
                                                (parseFloat(row.February) || 0) +
                                                (parseFloat(row.March) || 0) +
                                                (parseFloat(row.April) || 0) +
                                                (parseFloat(row.May) || 0) +
                                                (parseFloat(row.June) || 0) +
                                                (parseFloat(row.July) || 0) +
                                                (parseFloat(row.August) || 0) +
                                                (parseFloat(row.September) || 0) +
                                                (parseFloat(row.October) || 0) +
                                                (parseFloat(row.November) || 0) +
                                                (parseFloat(row.December) || 0);
                                          
                                              // Round the result to 2 decimal places using Math.round
                                              const totalBVFixed = Math.round(totalBV * 100) / 100;
                                          
                                              // Update totalSumBV by adding the totalBV for the row
                                              totalSumBV += totalBVFixed;
                                              return(	
											
                                              <tr>
													
                                                    <td>{index+1}</td>
                                                    <td>{totalBVFixed.toFixed(2)}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.DSID}</td>
                                                    <td>{row.MobileNo}</td>
                                                    <td>{row.January}</td>
                                                    <td>{row.February}</td>
                                                    <td>{row.March}</td>
                                                    <td>{row.April}</td>
                                                    <td>{row.May}</td>
                                                    <td>{row.June}</td>
                                                    <td>{row.July}</td>
                                                    <td>{row.August}</td>
                                                    <td>{row.September}</td>
                                                    <td>{row.October}</td>
                                                    <td>{row.November}</td>
                                                    <td>{row.December}</td>
                                                    
                                                    
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										   <tfoot>
                                            <tr>
													
                                                  
                                                 
                                                    <td></td>
                                                    <td><strong>Total= {totalSumBV.toFixed(2)} </strong></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
                                           </tfoot>
										</table>
                                        </div>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminDownlineRepurchaseIncome;