import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminBusinessBuildingClub()
{
    const [Rightdata,setRightdata]=useReducer(myreducer, initialstate);
    const [Leftdata,setLeftdata]=useReducer(myreducer, initialstate);
    const totalRightkit = Rightdata.mypost.reduce((sum, row) => sum + (row.quantity || 0), 0);
    const totalLeftkit = Leftdata.mypost.reduce((sum, row) => sum + (row.quantity || 0), 0);
   

    function getSmallerQuantity() {
        if (totalRightkit < totalLeftkit) {
            return totalRightkit;
        } else if (totalLeftkit < totalRightkit) {
            return totalLeftkit;
        } else {
            return 0;
        }
    }

      async function LoadRightdata()
   {
    var tempid=document.getElementById('txttempid').value;
    var month=document.getElementById('selectmonth').value;
     var year=document.getElementById('selectyear').value;
    

    fetchbygetmethod(setRightdata, 'AdminBusinessBuildingClubRight',{tempid:tempid,month:month,year:year});

     console.log("Rightdata",Rightdata);
   }

   async function LoadLeftdata()
   {
    var tempid=document.getElementById('txttempid').value;
    var month=document.getElementById('selectmonth').value;
     var year=document.getElementById('selectyear').value;
    

    fetchbygetmethod(setLeftdata, 'AdminBusinessBuildingClubLeft',{tempid:tempid,month:month,year:year});

     console.log("Leftdata",Leftdata);
   }

        function display(){
            LoadRightdata();
            LoadLeftdata();
      var data=document.getElementById("tabledata");
      var data2=document.getElementById("matchingdata");
       data.style.display='block'
       data2.style.display='block'
    }
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Business Building Club Detail</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Business Building Club Detail</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                 <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>DS ID</label>
                                                     <input type="text" class="form-control" id='txttempid'/>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id='selectmonth'>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id='selectyear'>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option selected='true' >2024</option>
                                                                <option>2025</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:833}} onClick={() => { display(); LoadRightdata();  LoadLeftdata();}}>
                                                            Submit</button>    
                                                                
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div style={{display:'none'}} id='matchingdata'>
																<div >
																 <label type="text" class="form-control"  style={{textAlign:'center',fontWeight:'900',fontSize:'x-large'}}  >Your's Total Matching Point: {getSmallerQuantity()}</label>
															</div>
															</div>
														</div>
										
									</div>
								</div>
							</div>
						</div>
                                
                                <div className="row" style={{display:'none'}} id='tabledata'>
                                 <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label style={{marginLeft:175,fontWeight:'900',fontSize:'large',color:'red'}}>Total Left = {totalLeftkit}</label>
                                                        <div style={{ height: '300px', overflowY: 'auto' }}>
                                                     <table id="datatable-buttons" class="table table-striped table-bordered ">
                                                        <thead>
												<tr>
                                                    <th>Sr No.</th>
												    <th>DS Id</th>
												    <th>Applicant Name</th>
													<th>Kit</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
                                            <tbody >

                                            {Leftdata.mypost.length>0 &&
											  Leftdata.mypost.map((row,index)=>{
                                              
                                              return(

                                            <tr>
                                                   <td>{index+1}</td>
												    <td>{row.tempid}</td>
												    <td>{row.applicantname}</td>
													<td>{row.quantity}</td>
													
                                                    
                                                
													
													
													
												</tr>
                                                 ) 
                                                })
                                                  
                                                  }

                                            </tbody>
                                                     </table>
                                                     </div>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label style={{marginLeft:150,fontWeight:'900',fontSize:'large',color:'red'}}>Total Right = {totalRightkit}</label>
                                                    <div style={{ height: '300px', overflowY: 'auto' }}>
                                                     <table id="datatable-buttons" class="table table-striped table-bordered">
                                                        <thead>
												<tr>
                                                    <th>Sr No.</th>
												    <th>DS Id</th>
												    <th>Applicant Name</th>
													<th>Kit</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>

                                            <tbody >

                                            {Rightdata.mypost.length>0 &&
											  Rightdata.mypost.map((row,index)=>{
                                              
                                              return(

                                            <tr >
                                                    <td>{index+1}</td>
												    <td>{row.tempid}</td>
												    <td>{row.applicantname}</td>
													<td>{row.quantity}</td>
													
                                                    
                                                
													
													
													
												</tr>
                                                 ) 
                                                })
                                                  
                                                  }

                                            </tbody>
                                                     </table>
                                                     </div>
                                                     </div>
                                               
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                               
                                    
                                     
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminBusinessBuildingClub;