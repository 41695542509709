import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminTDSreport()
{
   
    const [totaltds, setTotatds] = useState(0);
    const [totalnetcommission, setTotalnetcommission] = useState(0);

    const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
    async function loaddata()
 {
    var month=document.getElementById('txtmonth').value;
    var year=document.getElementById('txtyear').value;
 
  fetchbygetmethod(setmydata, 'AdminTDSreport',{month:month,year:year});

   console.log("mydata",mydata);
 }
    
      
     
     // useEffect(()=>{loaddata();},[]);
function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }

   useEffect(() => {
    
    let tdsTotal = 0;
    let netCommissionTotal = 0;
    mydata.mypost.forEach((row) => {
      
      tdsTotal += row.TDSAmt;
      netCommissionTotal += row.NetCommission;
    });

   
    setTotatds(Number(tdsTotal.toFixed(2)));
    setTotalnetcommission(Number(netCommissionTotal.toFixed(2)));
  }, [mydata]);



    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Monthly Wise TDS Report</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Monthly TDS Report</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id="txtmonth">
                                                                <option>Select</option>
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id="txtyear">
                                                    <option>Select</option>
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option>2024</option>
                                                                <option selected='true'>2025</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <input type="button" value="Submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:1000}} onClick={display} />
                                                              
                                                             <input type="button" value="Download"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}} />
                                                            
                                                        
                                                    </div>
                                                </div> 
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body" style={{overflow: 'auto'}}>
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>APPLICANT NAME</th>
												    <th>TEMP ID</th>
													<th>PAN NO</th>
                                                    <th>IFSC CODE</th>
                                                    <th>TDS AMT</th>
                                                    <th>TDS(%)</th>
                                                    <th>NET COMMISSION</th>
                                              
													</tr>
											</thead>
											<tbody>
                                            {mydata.mypost.length > 0 ? (
                                                                mydata.mypost.map((row, index) => (	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.TempID}</td>
                                                    <td>{row.PanNo}</td>
                                                    <td>{row.IFSCNo}</td>
                                                    <td>{row.TDSAmt}</td>
                                                    <td>{row.TDSPer}</td>
                                                    <td>{row.NetCommission}</td>
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                        ))
												
                                    ) : null}
											</tbody>
										 <tfoot>
                                            <tr>
													
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>Total={totaltds}</strong></td>
                                                    <td></td>
                                                    
                                                    
                                                   
                                                    <td><strong>Total={totalnetcommission}</strong></td>
                                                    
                                                    
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
                                           </tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminTDSreport;