import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import axios from 'axios';
import { Gotohome } from '../Commonfiles/checkfortoken';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';
//import { Input } from 'reactstrap';
function DscommunicationDetails() {
	const imagedefault = "/assets/images/users/avatar-1.jpg";
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);
	//const [editableInputTypes, setEditableInputTypes] = useState({ { text: null } });
	//photo section

	const [fileaddress, setfileaddress] = useState(null);
	const [fileaddressimage, setfileaddressimage] = useState(null);

	const handleFileChangeforaddress = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfileaddressimage(reader.result);
				setfileaddress(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitaddress = async (e) => {

		e.preventDefault();

		if (!fileaddress) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_address';
		// Create a new file with the new name
		const newFileName = `${imagename}.${fileaddress.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([fileaddress], newFileName, { type: fileaddress.type });

		const formData = new FormData();

		formData.append('file', newFile);


		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'communicationuploads', { option: 1, fileName: fileName, applicantid: localStorage.getItem('applicantID') });

			alert("Image Uploaded!!");
		} catch (err) {
			console.error('ret', err);
		}
	};

	const [fileIdproof, setfileIdproof] = useState(null);
	const [fileIdproofimage, setfileIdproofimage] = useState(null);
	const handleFileChangeforIdproof = (event) => {
		const imgfile = event.target.files[0];

		if (imgfile) {


			const reader = new FileReader();
			reader.onloadend = () => {

				setfileIdproofimage(reader.result);
				setfileIdproof(imgfile);

			};
			reader.readAsDataURL(imgfile);

		}
	};

	const onSubmitIdproof = async (e) => {

		e.preventDefault();

		if (!fileIdproof) {

			console.error('No file selected.');
			return;
		}
		const imagename = localStorage.getItem('tempID') + '_Idproof';
		// Create a new file with the new name
		const newFileName = `${imagename}.${fileIdproof.name.split('.').pop()}`; // e.g., dsid.jpg
		const newFile = new File([fileIdproof], newFileName, { type: fileIdproof.type });

		const formData = new FormData();

		formData.append('file', newFile);

		try {
			const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { fileName } = res.data;

			console.log('fileName', fileName);

			fetchbypostmethod(setmyimgupload, 'communicationuploads', { option: 2, fileName: fileName, applicantid: localStorage.getItem('applicantID') });
			alert("Image Uploaded!!");

		} catch (err) {
			alert("err" + err);
			console.error('ret', err);
		}
	};
	
	async function loaddata() {

		fetchbygetmethod(setmydata, 'communicationdetail', { tempid: localStorage.getItem("tempID") });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
	}

	useEffect(() => { loaddata() }, [])
	useEffect(() => {
		// if (isFirstRender.current) {
		// 	isFirstRender.current = false;
		//   } else {
		//console.log("mydata",myimgupload);
		if (myimgupload.myerror === true)
			alert("Please Upload Your Image Again!!");
		else if (myimgupload.initialstage === false && myimgupload.myloading === false) {
			alert('File uploaded Successfully');
			loaddata();

		}

		// }

	}, [myimgupload])
	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Update Profile</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						{mydata.initialstage == true ? <></> : mydata.myloading == true ? <Spinner />
							: mydata.myerror == true ? <Nodatafound />
								:
								  
									
										<div class="row">
											<div class="col-sm-12">
												<div class="panel panel-primary">
													<div class="panel-body">
														<h4 class="m-t-0 m-b-30">Communication Details</h4>
														<hr width="100%" />
														<div class="row">
															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form class="" action="#">
																		<div class="form-group">
																			<label>Country</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].CountryName!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].CountryName:''}
																			/>
																		</div>
																		<div class="form-group">
																			<label>City</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].Cityname!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].Cityname:''}
																			/>
																			


																		</div>
																		<div class="form-group">
																			<label>House No.</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].HouseNo!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].HouseNo:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>Mobile No.</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].MobileNo!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].MobileNo:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>Address Proof Type</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].AddPType!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].AddPType:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>Address Proof Image</label>
																			<div>
																				<input type="file" class="form-control" onChange={handleFileChangeforaddress} />
																			</div>
																			<div>
																				<img style={{ height: 300, width: 300 }} src={fileaddress ? fileaddress : Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].AddressProof ? './uploads/' + mydata.mypost[0].AddressProof : process.env.PUBLIC_URL + imagedefault} alt=""
																					className="img-circle" />

																			</div>

																		</div>
																		<div class="form-group">
																			<div>

																				<button type="reset" onClick={(e) => onSubmitaddress(e)}
																					class="btn btn-primary waves-effect m-l-5">
																					Submit AddressProof</button>
																			</div>
																		</div>





																	</form>
																</div>
															</div>
															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form action="#">
																		<div class="form-group">
																			<label>State</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].StateName!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].StateName:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>Area</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].areaname!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].areaname:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>Email</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].Email!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].Email:''}
																			/>
																			
																		</div>


																		<div class="form-group">
																			<label>Phone No. Phone no with STD code.</label>
																			<input type='text'
																				class="form-control"
																				readOnly={mydata.mypost.length > 0 && mydata.mypost[0].PhoneNo!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].PhoneNo:''}
																			/>
																			
																		</div>
																		<div class="form-group">
																			<label>ID Proof Type</label>
																			<div>
																				<input type="text" class="form-control" required
																					data-parsley-min="6" placeholder="Min value is 6" 
																					readOnly={mydata.mypost.length > 0 && mydata.mypost[0].IdProof!=null?true:false}
																				defaultValue={mydata.mypost.length > 0 ?mydata.mypost[0].IdProof:''}
																			/>
																			</div>
																		</div>
																		<div class="form-group">
																			<label>Id Proof Image</label>

																			<div>
																				<input type="file" class="form-control" onChange={handleFileChangeforIdproof}
																				/>
																			</div>
																			<div>
																				<img style={{ height: 300, width: 300 }}
																					src={fileIdproofimage ? fileIdproofimage : Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].IdProof ? './uploads/' + mydata.mypost[0].IdProof : process.env.PUBLIC_URL + imagedefault} alt=""
																					className="img-circle" />

																			</div>
																		</div>
																		<div class="form-group">
																			<div>
																				<button type="submit" onClick={(e) => onSubmitIdproof(e)}
																					class="btn btn-primary waves-effect waves-light">
																					Submit Id Proof</button>


																			</div>
																		</div>
																		
																	</form>




																</div>

															</div>


														</div>
													</div>
												</div>
											</div>
										</div>
									
								
						}
					</div>
				</div>
			</div>
			<Gotohome mydata={mydata} />
		</div>
	)
}

export default DscommunicationDetails;