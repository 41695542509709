import { useState, useEffect, React, useReducer } from 'react';
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../../Commonfiles/Myreducer';

function AdminOpenChoiceBillReport()
{
   
     const [mydata,setmydata]=useReducer(myreducer, initialstate);
    
    
      async function loaddata()
   {
    var date=document.getElementById('date').value;
    var month=document.getElementById('selectmonth').value;
    var year=document.getElementById('selectyear').value;
    
   
    fetchbygetmethod(setmydata, 'AdminOpenChoiceBillReport',{day:date,
        month:month,year:year});

     console.log("mydata",mydata);
   }
     
   function display(){
    loaddata();
var data=document.getElementById("tabledata");
data.style.display='block'

}




    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Open Choice</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Open Choice</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                
                                 <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>Day</label>
                                                       <select  class="form-control" id='date'>
                                                       <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
        <option>13</option>
        <option>14</option>
        <option>15</option>
        <option>16</option>
        <option>17</option>
        <option>18</option>
        <option>19</option>
        <option>20</option>
        <option>21</option>
        <option>22</option>
        <option>23</option>
        <option>24</option>
        <option>25</option>
        <option>26</option>
        <option>27</option>
        <option>28</option>
        <option>29</option>
        <option>30</option>
        <option>31</option>

                                                       </select>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control" id="selectmonth">
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control" id="selectyear">
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option>2024</option>
                                                                <option selected='true'>2025</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:830}} onClick={() => { display(); loaddata(); } }>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>From Bill</th>
												    <th>Invoice No</th>
													<th>Bill Date</th>
                                                    <th>Invoice Amt</th>
                                                    <th>To Bill</th>
                                                    <th>Return</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.mypost.length>0 &&
											  mydata.mypost.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.fillfrom}</td>
                                                    <td>{row.InvoiceNo}</td>
                                                    <td>{row.d}</td>
                                                    <td>{row.invoicebv}</td>
                                                    <td>{row.billto}</td>
                                                    <td>{row.InvoiceType}</td>
                                                  
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminOpenChoiceBillReport;