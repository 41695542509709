import { useState, useEffect, React, useReducer } from "react";
import { Gotohome } from '../Commonfiles/checkfortoken';


import Invoice from "../invoicebill";
import { fetchbygetmethod, fetchbypostmethod, initialstate, myreducer } from "../Commonfiles/Myreducer";
import { useFetcher } from "react-router-dom";

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function StoreAddEditSaleinvoice() {
    //from database
    const [showbill, setshowbill] = useReducer(myreducer, initialstate);
    const [item, setitem] = useReducer(myreducer, initialstate);
    const [myinvoiceno, setinvoiceno] = useReducer(myreducer, initialstate);
    const [dstempid, setdstempid] = useReducer(myreducer, initialstate);
    const [coupondetail, setcoupondetail] = useReducer(myreducer, initialstate);
    const [mycouponavailability, setcouponavailability] = useReducer(myreducer, initialstate);
    const [couponinsert, setcouponinsert] = useReducer(myreducer, initialstate);
    const [buttonClicked, setButtonClicked] = useState(false);
    //to database
    const [jsonsaleitems, setjsonsaleitems] = useState([]);
    const [jsonsaleinvoice, setjsonsaleinvoice] = useState(false);

    var [totalamount, settotalamount] = useState(0);
    var [totalbv, settotalbv] = useState(0);
    var [msg, setmsg] = useState('');


    function search() {
        // Declare variables
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("txtsearch");
        filter = input.value.toUpperCase();
        table = document.getElementById("datatable-responsive");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            for (var ci = 2; ci < 4; ci++) {
                td = tr[i].getElementsByTagName("td")[ci];
                if (td) {
                    txtValue = td.textContent || td.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                        break;
                    } else {
                        tr[i].style.display = "none";
                    }
                }//end if
            }//end loop column
        }//end for lopp row
    }

    function setcurrentdate() {
        const now = new Date();

        // Format the date as YYYY-MM-DD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

        // Set the value of the input element
        document.getElementById('txtinvoicedate').value = currentDateTime;
        document.getElementById('txtinvoicedate').readOnly = true;

    }

    async function loadcoupondetail() {
        //e.preventDefault();

        fetchbygetmethod(setcoupondetail, 'remainingcoupan', { applicantid: parseInt(document.getElementById('lblapplicantid').textContent) })

    }
    async function loadcouponavailability() {
        // e.preventDefault();


        fetchbygetmethod(setcouponavailability, 'couponavailability', { 'jsondata': JSON.stringify(jsonsaleitems) })

    }
    async function savecoupontodatabase(fromapplicantid,applicantid,invoiceno) {
        // console.log("showing",showbill);
        // console.log("fromapplicantid",fromapplicantid);
        // console.log("applicantid",applicantid);
        // console.log("invoiceno",invoiceno);
        // console.log("mycouponavailabilitying",mycouponavailability.mypost[0].discountstatus);
        //if(showbill && showbill.mypost.length>0)
            {
            if(mycouponavailability.mypost[0].discountstatus==='DA')
            {
             
              fetchbypostmethod(setcouponinsert, 'couponinsert',
                {
                    fromapplicantid: fromapplicantid,
                    applicantid: applicantid,
                    invoiceno:invoiceno,
                    discount: coupondetail.mypost[0].couponvalue
                })
            }
            
 }

       

    }
    async function loaditemdata() {

        //   settotalamount(0);
        //   settotalbv(0);
        fetchbygetmethod(setitem, 'getitemsbyapplicantid', { applicanttype: localStorage.getItem("applicantType"), applicantid: localStorage.getItem("applicantID") })

    }
    async function loaddsdata() {
        var tempid = document.getElementById('txtdstempid');

        fetchbygetmethod(setdstempid, 'getpanelchildbyparentidstore', { tempid: tempid.value });

    }

    async function savedatatodatabase() {
        console.log("jsonitemdata", jsonsaleitems);
        console.log("jsoninvoicedata", jsonsaleinvoice);
        try {




            fetchbypostmethod(setshowbill, 'saveinvoice', { 'jsondata': jsonsaleinvoice });
            savecoupontodatabase(jsonsaleinvoice.FromApplicantId,jsonsaleinvoice.ApplicantId,jsonsaleinvoice.InvoiceNo);

        } catch (error) {
            console.log("show error", error);
        }
    }
    function createdatafordatabase() {


        const invoiceNoSelect = document.getElementById('txtinvoicetype');
        const fillTempId = document.getElementById('txtdstempid');



    //   if(invoicenoselect.value==="0" || invoicenoselect.value === "---Select---"){ 
    //     alert('Fill The Invoice Type');
    //   }


    // //  alert(filltempid.textContent.length);
    //    else if (filltempid.textContent==="" || filltempid.textContent.length>0 ) {
    //         alert('First Fill DS ID');
    //     }


    if (!invoiceNoSelect || invoiceNoSelect.value === "0" || invoiceNoSelect.value === "---Select---") {
        alert('Please select a valid Invoice Type.');
        return; // Exit the function if validation fails
    }

    // Validate DS ID field (check `value` instead of `textContent` for input fields)
    const tempIdValue = fillTempId.value?.trim(); // Use value property and trim whitespace
    if (!tempIdValue) {
        alert('Please fill in the DS ID.');
        return; // Exit the function if validation fails
    }
    

    
   
    //   else{savedata();}  

      setButtonClicked(true);
    

    }
    
    // } else {
    //     alert("No coupons available.");
    // }


    
    function additem(currentitem, e, index) {
        console.log("jsonitems",jsonsaleitems);

        var currentsaleqty = parseInt(document.getElementById("txtsaleqty-" + index).value);
        currentsaleqty = isNaN(currentsaleqty) ? 0 : currentsaleqty;
        settotalamount(isNaN(totalamount) ? 0 : totalamount);
        settotalbv(isNaN(totalbv) ? 0 : totalbv);





        if (jsonsaleitems ) //true or false(uncheck) i.e. remove item from json
        {
            const itemexists=jsonsaleitems.some(row=>row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno);
            if(itemexists)
            {

          
            // alert("old");
            // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);
            if (currentsaleqty > currentitem.Quantity)//stock is less then demand
            {
                alert("Please check Available Quantity of " + currentitem.itemname);

                e.target.value = 0; // Unselect the checkbox
                currentsaleqty = 0;

            }
            //alert(currentsaleqty);
            var rowindex = jsonsaleitems.findIndex((row) => (row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));

            var previoustotalbv = totalbv - (jsonsaleitems[rowindex].businessvolume * jsonsaleitems[rowindex].Qty)
            var previoustotalamount = totalamount - (jsonsaleitems[rowindex].SellingPrice * jsonsaleitems[rowindex].Qty)


            // alert("previoustotalamount"+totalamount+"-"+(jsonsaleitems[rowindex].businessvolume ));//* jsonsaleitems[rowindex].Qty ));

            previoustotalamount = isNaN(previoustotalamount) ? 0 : previoustotalamount;
            previoustotalbv = isNaN(previoustotalbv) ? 0 : previoustotalbv;

            //step 1
            const updatedsingleitem={...jsonsaleitems[rowindex],Qty:currentsaleqty};
            //alert("previoustotalamount"+previoustotalamount);
            //step 2
           const updatedarrayitem=jsonsaleitems.filter((row) => !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno));

           //step 3

           updatedarrayitem.push(updatedsingleitem);
          //  jsonsaleitems[rowindex].Qty = currentsaleqty;
          //step 4
          setjsonsaleitems(updatedarrayitem);
          

            settotalbv(previoustotalbv + (updatedsingleitem.businessvolume * updatedsingleitem.Qty));
            settotalamount(previoustotalamount + (updatedsingleitem.SellingPrice * updatedsingleitem.Qty));

            if (currentsaleqty == 0 || isNaN(currentsaleqty)) {
                setjsonsaleitems(jsonsaleitems.filter((row) => !(row.ItemId === currentitem.ItemId && row.batchno === currentitem.batchno)));

            }

              }//end some exists


       
        else if (isNaN(currentsaleqty)) //if alphabet then error...only number needed 
        {
            alert("Please enter a valid quantity.");
            e.target.checked = false;//checkbox
            e.target.value = 0;
            return; // Exit function if saleqty is not a number
        }
        else if (currentsaleqty > currentitem.Quantity)//stock is less then demand
        {
            alert("Please check Available Quantity of " + currentitem.itemname);

            e.target.checked = false; // Unselect the checkbox
            e.target.value = 0;

        }
        else if(itemexists===false) {
             //alert("new");
            currentitem.ItemName = currentitem.itemname;
            currentitem.Qty = currentsaleqty;
            currentitem.InvoiceType = "S";
            currentitem.FromApplicantId = parseInt(localStorage.getItem("applicantID"));
            currentitem.ApplicantId = parseInt(document.getElementById('lblapplicantid').textContent);
            currentitem.InvoiceNo = document.getElementById("txtinvoiceno").value;
            currentitem.Rate = currentitem.SellingPrice;
            currentitem.BV = currentitem.businessvolume;
            currentitem.Unit = null;
            currentitem.VAT = null;
            currentitem.SAT = null;
            currentitem.Status = 'Y';
            currentitem.expirydate = null;
            currentitem.surcharge = null;
            currentitem.invoicereturn = null;
            currentitem.IsFullValue = null;
            // currentitem.SellingPrice1='';
            currentitem.RelativeInvoiceDate = new Date(document.getElementById("txtinvoicedate").value);
            currentitem.FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
            currentitem.fromitemdetailid = null;
            currentitem.toitemdetailid = null;
            currentitem.InvoiceDate = null;
            currentitem.GST = 'Y';
            currentitem.batchno = currentitem.batchno;


          
            settotalamount(totalamount + (currentitem.SellingPrice * currentsaleqty));
            settotalbv(totalbv + (currentitem.businessvolume * currentsaleqty));

            jsonsaleitems ?
            setjsonsaleitems([...jsonsaleitems, currentitem])
            : setjsonsaleitems([currentitem]);
            //totamt=totamt+amt;
            //settotamt(totamt+amt);//100+200=300

        }
    }
    }
    function savedata() {

        //var appid= document.getElementById("ddlpanelchild").value;
        //alert("id"+appid);
        //  alert("ji"+ document.getElementById("ddlpanelchild").value);
        // var jsonreplace=JSON.stringify(jsonsaleitems);
        // var     saleitemdata = jsonreplace.replace(/\\/g, "");
        // console.log("replavce data",saleitemdata);
        var saleinvoice = {
            "StorageLocationId": 1,
            "FromApplicantId": parseInt(localStorage.getItem("applicantID")),
            "ApplicantId": parseInt(document.getElementById('lblapplicantid').textContent),

            //(document.getElementById("txtdstempid").value.ApplicantId),
            //   document.getElementById("ddlpanelchildbranch").value:
            //   document.getElementById("ddlpanelchilddepot").value),
            "InvoiceType": document.getElementById("txtinvoicetype").value,
            "InvoiceNo": document.getElementById("txtinvoiceno").value,
            "InvoiceDate": new Date(document.getElementById("txtinvoicedate").value),
            "Reference": document.getElementById("txtreference").value,
            "FormNo": null,
            "PanNo": null,
            "VatNo": null,
            "VehicleNo": null,
            "Weight": null,
            "IsDelivered": "N",
            "Remark": null,
            "status": "Y",
            "packets": 1,
            "transportname": null,
            "DeliverAt": null,
            "invoiceamt": totalamount,
            "invoicebv": totalbv,
            "ip": null,
            "FinancialYear": parseInt(process.env.REACT_APP_FINANCIAL_YEAR),
            "GST": "Y",
            "FrieghtCharges": null,
            "LPCharges": null,
            "InsuranceCharges": null,
            "OtherCharges": null,
            "ReverseCharge": null,
            "grno": null,
            "Dos": null,

            "SaleItems": jsonsaleitems
        }
        //console.log(saleinvoice);

        setjsonsaleinvoice(saleinvoice)
        //console.log("myjsondata",jsonsaleinvoice);
        //  savedatatodatabase();
    }


    async function Createinvoiceno() {

        //alert("hiii");
        var ddlinvoicetype = document.getElementById('txtinvoicetype').value;
        var invoiceno = document.getElementById('txtinvoiceno');
        var FinancialYear = parseInt(process.env.REACT_APP_FINANCIAL_YEAR);
        var FromApplicantId = localStorage.getItem('applicantID');
        var Reference = document.getElementById("txtreference");


        // var x = await fetch(process.env.REACT_APP_URL + );
        // var y = await x.json();
        // var z = y.recordset;
        // console.log('getinvoicecount', z);

        fetchbygetmethod(setinvoiceno, 'getinvoicecount', { InvoiceType: ddlinvoicetype, FromApplicantId: FromApplicantId, FinancialYear: FinancialYear })

        //alert(invoiceno);


    }
    function fillinvoicenoafterusestateupdates() {
        if (myinvoiceno.mypost != null && myinvoiceno.mypost.length > 0) {
            var Reference = document.getElementById("txtreference");
            var invoiceno = document.getElementById('txtinvoiceno');
            // console.log('getinvoicecount',dstempid);//.mypost!=null ? dstempid.mypost:'NA');
            invoiceno.value = "Store" + localStorage.getItem("applicantID") + "-TI" +(parseInt(myinvoiceno.mypost[0].invoiceno)+1).toString();
            invoiceno.readOnly = true;
            Reference.value = myinvoiceno.mypost[0].invoiceno+1;
            Reference.readOnly = true;
        }
 
    }
    function filldstempidafterusestateupdates() {
        if (dstempid.mypost != null && dstempid.mypost.length > 0) {
            var dsapplicantID = document.getElementById('lblapplicantid');
            var dsapplicantname = document.getElementById('lblapplicantname');

            // setdstempid(z);
            // console.log('dstempidusestate', dstempid.value);

            dsapplicantID.textContent = dstempid.mypost[0].ApplicantID;
            dsapplicantname.textContent = dstempid.mypost[0].ApplicantName;
            dsapplicantID.style.display = "none";
              
            loadcoupondetail();
        }

    }

    

    useEffect(setcurrentdate, []);

    useEffect(() => { if (jsonsaleinvoice) savedatatodatabase() }, [jsonsaleinvoice]);

    useEffect(() => {

        filldstempidafterusestateupdates();
        fillinvoicenoafterusestateupdates();

    }, [dstempid, myinvoiceno]);

    useEffect(() => {
        console.log("coupondetail", coupondetail);
        loadcouponavailability();
        if (coupondetail.mypost.length > 0) {
            console.log("jsonsaleitemdata", jsonsaleitems);
              //filter or remove all items with qty is zero
             //setjsonsaleitems(jsonsaleitems.filter(item=>item.Qty===0));
           
        }



    }, [coupondetail])

    useEffect(() => {
        console.log("mycouponavailability", mycouponavailability);
        if (mycouponavailability.mypost.length > 0) {
            const currentdate = new Date();
            const lastdate = new Date(coupondetail.mypost.length>0?coupondetail.mypost[0].lasttakendiscountdate:null);

             console.log("lasttakendiscountdate",lastdate);
             console.log("currentdate",currentdate);
       
            if (
                !(currentdate.getMonth() === lastdate.getMonth()
                    &&
                    currentdate.getFullYear() === lastdate.getFullYear())
            ) 
            {  //alert('इस महीने तो आप छूट ले ही चुके हैं');
            
                if (coupondetail.mypost.length === 0 || coupondetail.mypost[0].couponvalue===0) {
                    setmsg('आपके पास कोई डिस्काउंट नहीं है');
                }

                else if (coupondetail.mypost[0].remainingcoupon === 0)
                    setmsg('आपके पास कोई डिस्काउंट नहीं है');
                else if (mycouponavailability.mypost[0].discountstatus === 'NDA')
                    setmsg('डिस्काउंट के लिए कृपिया हर्बल प्रोडक्ट की मात्रा बढ़ाएँ');
                else if (mycouponavailability.mypost[0].discountstatus === 'DA') {
                    setmsg('इस महीने ₹'+coupondetail.mypost[0].couponvalue+' छूट ले सकता है');
                    //savecoupontodatabase();
                }

            }
            else if(lastdate.getFullYear()==1970)
            {
                setmsg('आपके पास कोई डिस्काउंट नहीं है');
            }
            else {
                setmsg('इस महीने तो आप छूट ले ही चुके हैं');
                
            }


        }
      
    }, [mycouponavailability])

    useEffect(()=>{
        console.log("jsonsaleitemsbefore check1",jsonsaleitems);

       if(jsonsaleitems.length>0){
        console.log("jsonsaleitemsbefore check2",jsonsaleitems);
        loadcouponavailability();
       }
              
            else{
                settotalamount(0);settotalbv(0);
                loadcouponavailability();
            }
           
    },[jsonsaleitems])

    useEffect(()=>{
       console.log("showbill",showbill);
    },[showbill])

    function shouldrenderinvoice()
    {
        
     return (couponinsert.myerror===false && couponinsert.myloading===false && couponinsert.initialstage==false) 
             ||
             (couponinsert.initialstage===true &&  showbill.myerror === false && showbill.myloading === false 
                && showbill.initialstage===false)
    }

    return (<>{
           
           shouldrenderinvoice()
            
            ?

            <Invoice invoiceno={document.getElementById('txtinvoiceno')?.value || 'inv'} financialyear={parseInt(process.env.REACT_APP_FINANCIAL_YEAR)} option={1} />
            :
            <div className="content-page">
                <div className="content">
                    <div className="">
                        <div className="page-header-title">
                            <h4 className="page-title">Sale Invoice</h4>

                        </div>
                    </div>

                    <div className="page-content-wrapper ">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-body">
                                            <h4 className="m-t-0 m-b-30">Add /Edit Sale Invoice</h4>
                                            {/* <hr width="100%"/> */}
                                            <div className="row">
                                                <div className="form-group">
                                                    <div>


                                                        <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 800 }}>
                                                            Go Back</button>

                                                    </div>
                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Date</label>
                                                                <input type="datetime-local" id="txtinvoicedate" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Invoice Number</label>
                                                                <input type="text" id="txtinvoiceno" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Sale Invoice Type</label>
                                                                <select class='form-control' id="txtinvoicetype"  onChange={Createinvoiceno} required>
                                                                    <option value="0">---Select---</option>
                                                                    <option value="S" >Sale Invoice</option>
                                                                    <option value="T">Transfer Invoice</option>

                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Reference</label>
                                                                <input type="text" id="txtreference" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Direct Seller</label>


                                                                <input type="text" id="txtdstempid" onChange={loaddsdata} class='form-control' required />
                                                                <label id="lblapplicantname" class='form-control'></label>
                                                                <label id="lblapplicantid"></label>

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Return Invoice No.</label>
                                                                <input type="text" id="txtreturninvoiceno" class='form-control' placeholder="Return Invoice No." />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>


                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Vehicle Number</label>
                                                                <input type="text" id="txtvehicleno" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Remark</label>
                                                                <input type="text" id="txtremark" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Total Packet</label>
                                                                <input type="text" id="txtpacket" class='form-control' />

                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>



                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Transport Name</label>
                                                                <input type="text" id="txtTransport" class='form-control' />
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>
                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Is Block</label>
                                                                <select class='form-control'>
                                                                    <option>Unblock</option>
                                                                    <option>Block</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="col-sm-6 col-xs-12">

                                                    <div className="m-t-20">
                                                        <form className="" action="#">

                                                            <div className="form-group">
                                                                <label>Unit</label>
                                                                <select class='form-control'>
                                                                    <option>---Select Unit---</option>
                                                                    <option>PCS</option>
                                                                </select>
                                                            </div>



                                                        </form>
                                                    </div>


                                                </div>

                                                <div className="form-group">
                                                    <div>

                                                        <input type="button" class="btn btn-primary waves-effect waves-light" value="Add Items" data-toggle="modal"
                                                            data-target=".bs-example-modal-lg" onClick={loaditemdata} style={{ marginLeft: 700, marginTop: 49 }} />


                                                        <div class="modal fade bs-example-modal-lg" tabindex="-1"
                                                            role="dialog" aria-labelledby="myLargeModalLabel"
                                                            aria-hidden="true">
                                                            <div class="modal-dialog modal-lg">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <button class="close"
                                                                            data-dismiss="modal" aria-hidden="true">×</button>
                                                                        <h4 class="modal-title" id="myLargeModalLabel">Select Item</h4>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className='Records'>
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="panel panel-primary">
                                                                                        <div class="container">
                                                                                            <div class="row justify-content-center mt-5">
                                                                                                <div class="col-md-6">
                                                                                                    <form>
                                                                                                        <div class="input-group col-lg-12 ">
                                                                                                            <input type="text" class="form-control " id='txtsearch' onChange={search} placeholder="Search" aria-label="Search" />

                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="panel-body">

                                                                                            <label style={{ color: 'red', marginLeft: 5 }}>{msg}</label>

                                                                                            <label style={{ color: 'green', marginLeft: 25 }}>Total Amount =</label>
                                                                                            <label style={{ color: 'green', marginLeft: 5 }}>{totalamount}</label>
                                                                                            <label style={{ color: 'green', marginLeft: 5 }}>Total Bv =</label>
                                                                                            <label style={{ color: 'green', marginLeft: 5 }}>{totalbv}</label>
                                                                                            <label style={{ color: 'blue', marginLeft: 25 }}>Monthly Discount Amount =</label>
                                                                                            <label style={{ color: 'blue', marginLeft: 5 }}>{coupondetail && coupondetail.mypost.length>0 ?coupondetail.mypost[0].couponvalue:0}</label>
                                                                                            
                                                                                            <div class="table-container-Table">
                                                                                                <table id="datatable-responsive"
                                                                                                    class="table table-striped table-bordered dt-responsive nowrap"
                                                                                                    cellspacing="0" width="100%">
                                                                                                    <thead style={{position:"sticky",top:'0',zIndex:'100'}}>
                                                                                                        <tr>

                                                                                                            <th>Sale Quantity</th>
                                                                                                            <th>Item ID</th>
                                                                                                            <th>Item Name</th>
                                                                                                            <th>Item Rate</th>
                                                                                                            <th>BV</th>

                                                                                                            <th>Available Quantity</th>

                                                                                                            <th>Batch No</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        {item.mypost.length > 0 &&
                                                                                                            item.mypost.map((row, index) => {
                                                                                                                let itemrow=jsonsaleitems.find(item=>(item.ItemId===row.ItemId && item.batchno===row.batchno));
                                                                                                                let qty=itemrow ? itemrow.Qty:0;
                                                                                                                //console.log("itemrow",itemrow);
                                                                                                                return (<tr key={index}>

                                                                                                                    <td><input type="number" min="0" step="any" value={qty} style={{ width: 75 }} id={"txtsaleqty-" + index} onChange={(e) => additem(row, e, index)} /></td>
                                                                                                                    <td>{row.ItemId}</td>
                                                                                                                    <td>{row.itemname}</td>
                                                                                                                    <td>{row.SellingPrice}</td>
                                                                                                                    <td>{row.businessvolume}</td>
                                                                                                                    <td>{row.Quantity}</td>
                                                                                                                    <td>{row.batchno}</td>

                                                                                                                </tr>
                                                                                                                );
                                                                                                            })}
                                                                                                    </tbody>

                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <a href='invoicebill' target="_blank" >  */}
                                                        {/* <button type="submit" onClick={() => { createdatafordatabase() }}
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5, marginTop: 49 }} >
                                                            Submit</button> */}

{!buttonClicked && (
        <button
          type="submit"
          onClick={() => {
            createdatafordatabase();
          }}
          className="btn btn-primary waves-effect waves-light"
          style={{ marginLeft: 5, marginTop: 49 }}
        >
          Submit
        </button>
      )}



                                                        <br /><br />
                                                      {/*  <button type="submit" onClick={(e) => { loadcoupondetail(e) }}
                                                            className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 5, marginTop: 49 }} >
                                                            loadcoupondetail</button>*/}
                                                        {/* </a> */}

                                                    </div>
                                                </div>



                                            </div>








                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    }
    </>

    )

}



export default StoreAddEditSaleinvoice;